import React from 'react';
import Logop from '../Assets/m48-p.png';

const NewsletterSignUp = (props) => {
    if (!props.user) {
        return (
            <div className='bg-makeblue text-white p-10'>
                <div className='w-4/5 m-auto xl:flex sm:block'>
                    <div className='xl:w-1/2 sm:w-auto'>
                        <h1 className='text-5xl font-thin'>Get Started Now</h1>
                        <span className='text-2xl font-hairline pt-3 pr-5'>Sign up and quickly get up and running with Make48. Join the challenge and help build the future.</span>

                        <form>
                            <input onChange={(newValue) => { props.updateNewUser({ first_name: newValue.target.value }) }}
                                className='w-4/5 rounded-lg bg-transparent placeholder-white block my-5 p-3 border-solid border-2 border-opacity-25 border-white' type='first_name' id='first_name' placeholder='First Name'></input>
                            <input onChange={(newValue) => { props.updateNewUser({ last_name: newValue.target.value }) }}
                                className='w-4/5 rounded-lg bg-transparent placeholder-white block my-5 p-3 border-solid border-2 border-opacity-25 border-white' type='last_name' id='last_name' placeholder='Last Name'></input>
                            <input onChange={(newValue) => { props.updateNewUser({ email: newValue.target.value }) }}
                                className='w-4/5 rounded-lg bg-transparent placeholder-white block my-5 p-3 border-solid border-2 border-opacity-25 border-white' type='email' id='email' placeholder='E-mail'></input>
                            <input onChange={(newValue) => { props.updateNewUser({ password: newValue.target.value }) }}
                                className='w-4/5 rounded-lg bg-transparent placeholder-white block my-5 p-3 border-solid border-2 border-opacity-25 border-white' type='password' id='password' placeholder='Password'></input>
                        </form>

                        <button className='p-2 m-5 mx-0 p-4 xl:w-3/12 md:w-3/12 bg-button-green text-base shadow text-base rounded-lg' onClick={(e) => props.createNewUser(e)}>Sign up</button>



                    </div>
                    <div className='xl:w-1/2 sm:w-auto text-center m-auto'>
                        <img src={Logop} alt='idea' className='m-auto xl:mt-20 sm:mt-0 sm:p-10 xl:p-0'></img>
                    </div>

                </div>
            </div>
        )
    } else {
        return (
            <div className='bg-makeblue text-white p-10'>
                <div className='w-4/5 m-auto xl:flex sm:block'>
                    <div className='xl:w-1/2 sm:w-auto'>
                        <h1 className='text-5xl font-thin'>You're Logged In!</h1>
                        <span className='text-2xl font-hairline pt-3 pr-5'>Sign up for new challenges or review the ones you've applied too from the dashboard!</span>
                        
                    </div>
                    <div className='xl:w-1/2 sm:w-auto text-center m-auto'>
                        <img src={Logop} alt='idea' className='m-auto xl:mt-20 sm:mt-0 sm:p-10 xl:p-0'></img>
                    </div>

                </div>
            </div>
        )
    }
}

export default NewsletterSignUp