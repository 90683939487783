
import React from 'react'
import CriteriaDefaults from './AdminDashboard/CriteriaDefaults';
import RequirementDefaults from './AdminDashboard/RequirementDefaults';

function ChallengeDefaults() {
    return (
        <div className='xl:p-10 sm:p-0 overflow-x-hidden container px-4 grid grid-cols-2 gap-4'>
            <CriteriaDefaults />
            <RequirementDefaults />
        </div>
    );
}

export default ChallengeDefaults;