import React, { Component } from 'react'
import Card from '../Components/Home/Card'
import axios from 'axios'

class NavChallenger extends Component {
    state = {
        Card: false,
        Challenges: [],
        companies: [],
        newUser: {},
        loadMoreChallengesNum: 100,
        sortDirection: 'asc',
        isLoading: true,
        reRender: false,
    }

    constructor(props) {
        super(props);   
        this.loadData();
    }

    loadData = async () => {
        Promise.all([this.getChallenges(), this.getCompanies()])
            .then((res) => {

                let updatedChallenges = res[0].data.data;
                updatedChallenges.forEach(function (challenge) {
                    challenge.show_challenge = 0;
                    
                    if (challenge.remaining_days > -5) {
                        challenge.show_challenge = 1;
                    }
                });
                this.sortBy('remaining_days', 'asc');

                this.setState((curState) => {
                    return {
                        ...curState,
                        companies: res[1].data.data,
                        Challenges: updatedChallenges,
                        isLoading: false,
                        reRender: true
                    };
                });
            });
    }

    getCompanies = () => {
        return axios.get('/company').catch((err) => {
                console.log(err);
            });
    }
    
    getChallenges = () => {
        return axios.get('/challenge')
            .catch((err) => {
                console.log(err);
            });
    }

    loadMoreChallenges = (numChallenges) => {
        let numberUpdatedChallenges = 0;
        let updatedChallenges = this.state.Challenges.map((challenge, index) => {
            if (challenge.show_challenge === 0 && numberUpdatedChallenges < numChallenges) {
                challenge.show_challenge = 1;
                numberUpdatedChallenges++;
            }
            return challenge;
        });
        this.setState({ Challenges: updatedChallenges, reRender: true });
    }

    resetShowChallenges = () => {
        let updatedChallenges = this.state.Challenges.map((challenge, index) => {

            challenge.show_challenge = 0;
            return challenge;
        })
        this.setState({ Challenges: updatedChallenges, reRender: true });

    }

    disableClick = () => {
        if (this.state.disabled) {
            return;
        }
        this.setState({ disabled: true });
        // Send     
    }

    sortFunction = (sortProperty) => {
        if (this.state.Challenges) {
            return (a, b) => a[sortProperty] === b[sortProperty] ? 0 : a[sortProperty] < b[sortProperty] ? -1 : 1;
        }
    }

    sortBy(sortProperty) {
        let sortData = this.state.Challenges
        if (!this.state.sortDirection) {
            this.setState({ sortDirection: 'asc', reRender: false });
        }

        if (sortData) {
            sortData.sort(this.sortFunction(sortProperty));
            // console.log('Sort Test:', sortData)
            if (this.state.sortDirection === 'asc') {
                this.setState({ Challenges: sortData, reRender: false });
            } else {
                this.setState({ Challenges: sortData.reverse(), reRender: false });
            }
            this.resetShowChallenges();
            this.loadMoreChallenges(this.state.loadMoreChallengesNum);
        }
    }

    render() {

        let challenges = this.state.Challenges;
        // console.log('cards', cards);
        if (!challenges) return (null);
        return (

        <div className="container m-auto px-4">
            <div className='xl:w-full xl:flex xl:flex-wrap m-auto justify-center pt-5 sm:block sm:w-full'>
                <div className='block w-full text-center my-20'>

                    <h1 className='text-6xl font-thin font-display'>
                        All Challenges
                    </h1>
                </div>
                {this.state.isLoading === true && 
                    <div className="text-center"> 
                            <div className='loadding-icon'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                }
                <div className='grid lg:grid-cols-4 md:grid-cols-2 gap-4 justify-center'>
                    {challenges.map((challenge, index) => {
                        if (challenge.show_challenge === 1) {
                            return (
                                <Card data={challenge} key={index} companies={this.state.companies} />
                            )
                        }
                        return <></>;
                    })}
                </div>
            </div>
        </div>
        )
    }
};


export default NavChallenger;