import React from 'react';
import moment from 'moment'
import { Favorite, FavoriteBorder } from '@material-ui/icons';


const Card = (props) => {
    let companyLogo = false;
    let company = [];
    
    if (props.companies.length > 0 && !companyLogo) {
        company = props.companies.filter((company) => {
            if (props.data.company_id === company.id) {
                return company;
            }
            return null;
        })
        
        companyLogo = company[0].logo_url;
    }

    let image_url = process.env.REACT_APP_API_URL.replace('/api', '');

    var remainingDays;
    var utcDate = moment().utc();
    var endDate = moment(props.data.end_date);
    var daysLeft = endDate.diff(utcDate, "days");
    
    if(daysLeft > 48){
        remainingDays = "Coming Soon";
    }
    else if(daysLeft < 0)
    {
        remainingDays = "Completed";
    }
    else
    {
        remainingDays = daysLeft;
    }

    var cardTitle = props.data.name.length > 50 ? props.data.name.substring(0, 49) + "..." : props.data.name;

    return (

        <React.Fragment>
            <a href={'/challenge/' + props.data.id}>
                <div id='cardPreview' className='text-2xl border-solid border shadow rounded-lg m-auto bg-slate-50 hover:scale-105 transition-all duration-300'>
                    <div id='background' className='bg-cover bg-no-repeat bg-center w-full rounded-t-lg' style={{ backgroundImage: `url(${props.data ? (image_url + props.data.image_url) : ''})`, height: '250px' }}>
                        <div onClick={(e) => {e.preventDefault()}} className='absolute top-0 right-0 mt-3 mr-3'>
                            {remainingDays !== "Completed" ? 
                                <Favorite fontSize='large' color='secondary' /> :
                                <FavoriteBorder fontSize='large' color='secondary' />
                            }
                        </div>
                    </div>
                    <img src={image_url + companyLogo} alt='badge' id='icons' className='object-cover absolute ml-4 -mt-8 border-makeblue border-4 rounded-full'></img>
                        <span id='summary' className='p-3 pl-24 py-2 text-xl font-light text-end'>{cardTitle}</span>
                    <div className='m-3'>
                        <div>
                            <span className='text-lg text-gray-600'>${props.data.prize_money}</span>
                            <span className='text-lg text-gray-600 font-thin'> in prizes</span>
                        </div>
                        <div>
                            <span className='text-lg text-gray-600'>{remainingDays}</span>
                            {remainingDays < 48 && remainingDays > 0 && 
                                    <span className='text-lg text-gray-600 font-thin'> Days Left</span>
                                }
                        </div>
                    </div>
                    <div id='categoryType' className='text-start text-base mt-8 text-makeblue uppercase p-3'>
                        <span>{props.data.category.split(',').join(' - ')}</span>
                    </div>
                </div>
            </a>
        </React.Fragment>
    )

}

export default Card;


