import React from 'react';
import ReactDOM from 'react-dom';
import './tailwind.css';
import App from './App';
import axios from 'axios';
import { AlertProvider } from './Components/Globals/Hooks/AlertContext';
import { UserProvider } from './Components/Globals/Hooks/UserContext';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.responseType = 'json';
axios.defaults.withCredentials = true;
axios.defaults.headers = {'Accept': 'application/json'};

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const access_token = localStorage.getItem('access_token');
  config.headers.Authorization =  'Bearer ' + access_token;
  return config;
});


ReactDOM.render(
  <UserProvider>
    <AlertProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </AlertProvider>
  </UserProvider>,
  document.getElementById('root')
);

ReactDOM.render(
  <UserProvider>
    <AlertProvider>
      <App/>
    </AlertProvider>
  </UserProvider>
  , document.getElementById('root'));
