import React, { useState } from 'react'
import axios from 'axios';
import Logop from '../Assets/m48-p.png';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';

const More = (props) => {

    const [agree, setAgree] = useState(false);
    const [checked, setChecked] = React.useState(true);
    const [applyChallenge, setApplyChallenge] = useState();
    const [user, setUser] = useState();

    let challenge_id = props.match.params.id;
  
    const handleAgreeTerms = () => {
       setAgree(!agree);
    };

    const handleNewsletter = () => {
        setChecked(!checked);
    };
   
    const getUser = () => {
        axios.post('/me')
            .then(res => {
                setUser(res.data.id);
                checkHasUserApplied(res.data);
            })
            .catch(error => {
            })
    }
    if (!user) {
        getUser()
    }

    const checkHasUserApplied = (currentUser) => {
        if (currentUser && currentUser.challenges) {
            currentUser.challenges.forEach(challenge => {
                if (challenge.id === +challenge_id) {
                    setApplyChallenge(true);
                }
            });
        }
    }

    const updateApplyChallenge = (newApply) => {
        let newDataObject = {
            ...applyChallenge,
            ...newApply
        }
        setApplyChallenge(newDataObject);
        
    }

    const applyToChallenge = (e) => {
        // need to split first and last name before doing post
        axios.post('/challenge/' + challenge_id + '/user/' + user + '/apply/')
            .then(res => {
                let newApply = {
                    user: challenge_id,
                }
                updateApplyChallenge(newApply);
            }).catch((err) => {
                console.log(err);
            });
    }

    const checkApplied = () => {
        if (!applyChallenge) {
            return (
                <React.Fragment>
                    <h1 className='text-5xl font-thin'>Apply to Challenge!</h1>
                    <span className='text-2xl font-hairline pt-3 pr-5 block'>Sign up and quickly get up and running with Make48. Join the challenge and help build the future.</span>
                    <button disabled={!agree} className='p-6 mt-10 bg-button-green text-base shadow rounded-lg xl:w-1/2 sm:w-full' onClick={(e) => applyToChallenge(e)}>Apply to Challenge</button>

                    <div className='p-2'>
                        <FormControlLabel
                            label={<span className="select-none"> <span class="text-red-700"> *</span>I agree to <strong><a href='#'>terms and conditions</a></strong></span>}
                            control={<Checkbox color="primary" onChange={handleAgreeTerms}/>}
                        />
                        <FormControlLabel
                            label="Signup for newsletter"
                            control={<Checkbox color="primary" checked={checked} onChange={handleNewsletter}/>}
                        />
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <h1 className='text-5xl font-thin'>Challenge Accepted!</h1>
                    <span className='text-2xl font-hairline pt-3 pr-5 block'>You've applied to the challenge! Submit final results at your <a href='/dash'>Dashboard</a></span>
                </React.Fragment>
            )
        }
    }

    // console.log(props);


    return (
        <div className='bg-makeblue text-white p-10'>
            <div className='w-4/5 m-auto xl:flex sm:block'>
                <div className='xl:w-1/2 sm:w-auto'>

                    <div>
                        {checkApplied()}
                    </div>
                </div>
                <div className='xl:w-1/2 sm:w-auto text-center m-auto'>
                    <img src={Logop} alt='idea' className='m-auto xl:mt-20 sm:mt-0 sm:p-10 xl:p-0'></img>
                </div>

            </div>
        </div>
    )
}

export default More