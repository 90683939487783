import { Button, Chip, Divider, FormControl, InputLabel, List, ListItem, ListItemIcon, ListItemSecondaryAction, MenuItem, Select, TextField, makeStyles } from '@material-ui/core';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import M4Loader from '../../Globals/CommonComponents/M4Loader';
import { BuildOutlined, ClearOutlined, DeleteOutline, EditOutlined, SaveOutlined } from '@material-ui/icons';
import { AlertContext } from '../../Globals/Hooks/AlertContext';

const useStyles = makeStyles({
    input: {
        width: "60%",
        marginRight: "1em"
    },
    select: {
        minWidth: "150px"
    }
});

const InputTypeName = {
    "file": "File",
    "textSingle": "Textbox",
    "textMulti": "Multi-line Textbox"
};

function RequirementDefaults() {
    const classes = useStyles();

    const [requirements, setRequirements] = useState([]);
    const [isEditing, setEditing] = useState([]);
    const [isRequirementLoading, setRequirementLoading] = useState([]);

    const { setAlert } = useContext(AlertContext);

    const newRequirementId = 0;

    const loadRequirements = async () => {
        return await Axios.get('/requirement')
            .then((result) => {
                return result.data;
            });
    };

    useEffect(() => {
        Promise.all([loadRequirements()])
            .then((result) => {
                setRequirements(result[0]);
            });
    }, []);
    
    const isEditingRow = (headerId) => {
        return isEditing.includes(headerId);
    };

    const isLoadingRow = (headerId) => {
        return isRequirementLoading.includes(headerId);
    }

    const addRequirement = () => {
        setRequirements(requirements.concat([{
            header: "",
            id: newRequirementId
        }]));
        setEditing(isEditing.concat([newRequirementId]));
    };

    const insertRequirement = async () => {
        const requirement = requirements.find(r => r.id === newRequirementId);

        Axios.post('/requirement', {
            'title': requirement.title,
            'type': requirement.type
        })
        .then(async () => {
            setAlert('Requirement Added', 'success');
            setEditing(isEditing.filter(id => id !== newRequirementId));
            
            const newRequirements = await loadRequirements();
            setRequirements(newRequirements);
        })
    }

    const editRequirementTitle = (newValue, requirementId) => {
        const newRequirements = requirements.map(r => {
            if(r.id === requirementId){
                r.title = newValue;
            }
            return r;
        });
        setRequirements(newRequirements);
    };

    const editRequirementType = (newValue, requirementId) => {
        const newRequirements = requirements.map(r => {
            if(r.id === requirementId){
                r.type = newValue;
            }
            return r;
        });
        setRequirements(newRequirements);
    }

    const saveRequirement = (requirementId) => {
        setRequirementLoading(isRequirementLoading.concat(requirementId));
        const requirement = requirements.find(r => r.id === requirementId);

        Axios.put('/requirement/' + requirementId,
            {
                "id": requirementId,
                "title": requirement.title,
                "type": requirement.type,
            }
        ).then(() => {
            setRequirementLoading(isRequirementLoading.filter(r => r.id !== requirementId));
            setEditing(isEditing.filter(id => id !== requirementId));
        })
        .catch(() => {
            setRequirementLoading(isRequirementLoading.filter(r => r.id !== requirementId));
            setAlert('Error Saving Header', 'error');
        });
    };

    const deleteRequirement = (requirementId) => {
        setRequirementLoading(isRequirementLoading.concat(requirementId));

        Axios.delete(`/requirement/${requirementId}`)
        .then(() => {
            setRequirements(requirements.filter(r => r.id !== requirementId));
        });
    };

    const handleClear = () => {
        setRequirements(requirements.filter(r => r.id !== newRequirementId));
    };

    if(requirements === null) return null;

    return (
        <div>
            <h1 className='m-auto w-full my-5 text-makeblue text-2xl'>Requirements</h1>
            <Divider/>
            <List>
                {requirements.map(item => {
                    return (
                        <ListItem>
                            {isEditingRow(item.id) ? 
                                <>
                                    <TextField 
                                        value={item.title} 
                                        onChange={e => editRequirementTitle(e.target.value, item.id)}
                                        disabled={isLoadingRow(item.id)}
                                        className={classes.input}
                                    />
                                    <FormControl>
                                        <InputLabel id="type-select-label">Type</InputLabel>
                                        <Select 
                                            labelId='type-select-label'
                                            id='type-select'
                                            className={classes.select}
                                            autoWidth
                                            value={item.type}
                                            onChange={e => editRequirementType(e.target.value, item.id)}
                                        >
                                            <MenuItem value="file">{InputTypeName.file}</MenuItem>
                                            <MenuItem value="textSingle">{InputTypeName.textSingle}</MenuItem>
                                            <MenuItem value="textMulti">{InputTypeName.textMulti}</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {isLoadingRow(item.id) && 
                                        <ListItemIcon>
                                            <M4Loader size={18}/>
                                        </ListItemIcon>
                                    }
                                </>
                                : 
                                <>
                                    <div className={`${classes.input} cursor-pointer`} onClick={() => setEditing(isEditing.concat([item.id]))}>
                                        {item.title}
                                    </div>
                                    <Chip
                                        color="secondary"
                                        icon={<BuildOutlined />} 
                                        label={InputTypeName[item.type]}
                                    />
                                </>
                            }
                            {isEditingRow(item.id) &&
                                <ListItemSecondaryAction>
                                    {item.title !== "" ?
                                        <Button 
                                            onClick={() => {
                                                if(item.id === newRequirementId) {
                                                    insertRequirement();
                                                }
                                                else {
                                                    saveRequirement(item.id)
                                                }
                                            }}
                                            disabled={requirements.find(h => h.id === item.id).title === ""}
                                        >
                                            <SaveOutlined />
                                        </Button> 
                                        :
                                        <Button
                                            onClick={() => {
                                                handleClear();
                                            }}
                                        >
                                            <ClearOutlined />
                                        </Button>
                                    }
                                </ListItemSecondaryAction>
                            }
                            {!isEditingRow(item.id) &&
                                <ListItemSecondaryAction>
                                    <Button onClick={()=>{setEditing(isEditing.concat([item.id]))}}>
                                        <EditOutlined />
                                    </Button>
                                    <Button onClick={() => deleteRequirement(item.id)}>
                                        <DeleteOutline />
                                    </Button>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                    );
                })}
                <ListItem>
                    <Button
                        onClick={() => addRequirement()}
                        disabled={requirements.some(h => h.id === newRequirementId)}
                    >
                        Add Requirement
                    </Button>
                </ListItem>
            </List>
        </div>
    );
}

export default RequirementDefaults;