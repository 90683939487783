import React from 'react'
import {Link} from 'react-router-dom';
import Farms from '../Assets/farmSocial.png'
import Pace from '../Assets/paceFour.png'
import Stanley from '../Assets/stanleyLogo.png'
import Pg from '../Assets/unnamed-6.png'
import Ford from '../Assets/ford-flat-gb.png'

function Collaboration() {
    return (
        <React.Fragment>
        <div className="container m-auto px-4">
            <div className='sm:w-full m-auto xl:flex sm:block'>
                <div className='xl:text-left sm:text-center'>
                    <h1 className='pt-20 text-xl text-base'>It's all about Collaboration</h1>
                    <span className='text-3xl font-hairline pt-3 logo-content'>Proud to be supported by our Innovation Partners.</span>

                    <button className='text-makeblue py-10 block'>
                    <Link to="/sponsorship">Sponsorship <span> > </span></Link>
                    </button>
                </div>
                </div>
                <div className='text-center logo-images logo-imgs-area relative'>
                    <div className='md:flex md-flex-wrap items-center'>
                            <div className='lg:w-1/5 md:w-1/5 sm:w-1/2 w-full mb-4 h-200 border'>
                                <img className='w-auto m-auto' src={Stanley} alt="Stanley"/>
                            </div>

                            <div className='lg:w-1/5 md:w-1/5 sm:w-1/2 w-full mb-4 h-200 border'>
                                <img className='w-auto m-auto' src={Pace} alt="Pace"/>
                            </div>
                      

                        <div className='lg:w-1/5 md:w-1/5 sm:w-1/2 w-full mb-4 h-200 border'>
                                <img className='w-auto m-auto' src={Farms} alt="Cannington"/>
                            </div>

                        <div className='lg:w-1/5 md:w-1/5 sm:w-1/2 w-full mb-4 h-200 border'>
                            <img className='w-auto m-auto' src={Ford} alt="Cannington"/>
                        </div>

                        <div className='lg:w-1/5 md:w-1/5 sm:w-1/2 w-full mb-4 h-200 border'>
                            <img className='w-auto w-55 m-auto' src={Pg} alt="Cannington"/>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default Collaboration;