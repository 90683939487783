import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react'

const useStyles = makeStyles((theme) => {
    return ({
        root: {
            margin: '16px',
            width: '400px'
        }, 
        cardImage: {
            height: '250px'
        },
        cardTitle: {
            fontSize: theme.typography.h5.fontSize
        },
        cardAction: {
            
        }
    })
});

const url = process.env.REACT_APP_API_URL.replace('/api', '');

function DashboardCard(props) {
    var classes = useStyles();
    var challenge = props.challenge;

    return (
        <Card className={classes.root}>
            <Link to={`/challenge/${challenge.id}`}>
                <CardActionArea>
                    <CardMedia
                        className={classes.cardImage}
                        image={url + challenge.image_url}
                        title="Challenge Image"
                    />
                    
                        <CardContent>
                            <div className={classes.cardTitle}>
                                {challenge.name}
                                <Divider className="mx-4" />
                            </div>
                            <div>
                                {challenge.description}
                            </div>
                        </CardContent>
                    
                </CardActionArea>
            </Link>
            <CardActions>
                <Button onClick={props.onOpenSubmit} color='primary'>
                    Submit
                </Button>
            </CardActions>
        </Card>
    );
}

export default DashboardCard;