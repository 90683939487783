import React, {useState} from 'react'
import moment from 'moment'

function Zoom(props) {
    const [companyContact, setCompanyContact] = useState();

    let company = [];

    if (props.challenge && props.challenge.company_id && props.companies && !companyContact) {
        company = props.companies.filter((company) => {
            if (props.challenge.company_id === company.id) {
                return company;
            }
            return null;
        })
        setCompanyContact(company[0].first_name);
    }
    
    let ZoomOne = props.challenge.zoom_date_one ? moment(props.challenge.zoom_date_one).format('MMMM Do') : null;
    let ZoomTwo = props.challenge.zoom_date_two ? moment(props.challenge.zoom_date_two).format('MMMM Do') : null;
    let ZoomThree = props.challenge.zoom_date_three ? moment(props.challenge.zoom_date_three).format('MMMM Do') : null;
    
    let ZoomTimeOne = moment(props.challenge.zoom_time_one, 'HH:mm A').format('hh:mm A')
    let ZoomTimeTwo = moment(props.challenge.zoom_time_two, 'HH:mm A').format('hh:mm A')
    let ZoomTimeThree = moment(props.challenge.zoom_time_three, 'HH:mm A').format('hh:mm A')


    return (
        <div className='w-full m-auto text-center bg-works p-16 mt-20 relative'>
            <h1 className='text-5xl font-thin'>Weekly Zoom Meeting Information</h1>
            <span className='xl:w-2/4 sm:w-full m-auto text-2xl font-thin'>{companyContact} is available for Zoom meetings on the dates below. This is your chance to ask question to help guide you through the challenge. Once Registered, find the Zoom link on your dashboard.</span>
            <div className='p-10'>
                {ZoomOne ? (
                <span id='zoomRegOne' className='px-6 py-1 m-3 border-makeblue border rounded-full text-makeblue'>{ZoomOne}{'\n'}at {ZoomTimeOne}</span>): null
                }
                {ZoomTwo ? (
                <span id='zoomRegTwo' className='px-6 py-1 m-3 border-makeblue border rounded-full text-makeblue'>{ZoomTwo}{'\n'}at {ZoomTimeTwo}</span>): null
                }
                {ZoomThree ? (
                <span id='zoomRegThree' className='px-6 py-1 m-3 border-makeblue border rounded-full text-makeblue'>{ZoomThree}{'\n'}at {ZoomTimeThree}</span>): null
                }
            </div>

        </div>
    )
}

export default Zoom