import React, { Component } from 'react'
import LeaderOne from '../Assets/Userpic.png'


export class Firstplace extends Component {
    render() {
        return (
            <div className='block'>
                <figure className='flex rounded-full border shadow p-3 m-auto xl:mx-10 sm:mx-auto mt-10 object-contain figureOne'> 
                    <div>
                        <img className='firstWinner' src={LeaderOne} alt='test'/>
                    </div>                       
                </figure>

                <div className='flex w-16 py-4 flex-col border bg-leaderOrange text-white text-xl m-auto xl:mt-8 md:mt-2 sm:m-auto'>1
                
                </div>
            </div>
        )
    }
}

export default Firstplace
