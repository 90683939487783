import React from 'react'
import { Link } from 'react-router-dom';
import AnimatedModal from './Animatedmodal';


const NavigationRes = (props) => {
    
    if (props.user != null && props.user.role_id === 1) {
        function logout() {
            localStorage.clear();
            window.location.href = '/';
        }

        return (

            <React.Fragment>


                {/* {toAdmin ? <Redirect to='/admin'/>: null} */}

                <ul className="block lg:px-20">
                    <li className='p-5'>
                        <Link to="/" className='text-white text-thin'>Home</Link>
                    </li>
                    <li className='p-5'>
                        <Link to="/about" className='text-white text-thin'>About</Link>
                    </li>
                    <li className='p-5'>
                        <Link to="/challenges" className='text-white text-thin'>Challenges</Link>
                    </li>
                    <li className='p-5'>
                        <Link to="/sponsorship" className='text-white text-thin'>Sponsorship</Link>
                    </li>
                    {/* <li className='p-5'>
                    <Link to="/resources" className='text-white text-thin'>Resources</Link>
                    </li>
                   
                    <li className='p-5 pt-6'>
                    <Link to="/placeholder" className='text-white text-thin text-xl'><FaSearch/></Link>
                    </li> */}
                    <li className='p-5'>
                        <Link to="/admin-dashboard" className='text-white text-thin border-white border-opacity-50 border-2 p-2 px-8 rounded-lg'>Admin</Link>
                    </li>
                    <button className='text-white text-thin border-white border-opacity-50 p-5' onClick={logout}>Sign Out</button>
                </ul>
            </React.Fragment>
        );
    } if (props.user != null && props.user.role_id === 2) {
        function logout() {
            localStorage.clear();
            window.location.href = '/';
        }
        return (
            <React.Fragment>

                {/* {toHome ? <Redirect to="/user" /> : null} */}

                <ul className="block lg:px-20">
                    <li className='p-5'>
                        <Link to="/" className='text-white text-thin'>Home</Link>
                    </li>
                    <li className='p-5'>
                        <Link to="/about" className='text-white text-thin'>About</Link>
                    </li>
                    <li className='p-5'>
                        <Link to="/challenges" className='text-white text-thin'>Challenges</Link>
                    </li>
                    <li className='p-5'>
                        <Link to="/sponsorship" className='text-white text-thin'>Sponsorship</Link>
                    </li>
                    {/* <li className='p-5'>
                <Link to="/resources" className='text-white text-thin'>Resources</Link>
                </li>
             
                <li className='p-5 pt-6'>
                <Link to="/placeholder" className='text-white text-thin text-xl'><FaSearch/></Link>
                </li> */}
                    <li className='p-5'>
                        <Link to="/user" className='text-white text-thin border-white border-opacity-50 border-2 p-2 px-8 rounded-lg'>Dashboard</Link>
                    </li>
                    <button className='text-white text-thin border-white border-opacity-50 p-5' onClick={logout}>Sign Out</button>
                </ul>
            </React.Fragment>

        )

    } else {
        return (
            <ul className="block lg:px-20">
                <li className='p-5'>
                    <Link to="/" className='text-white text-thin'>Home</Link>
                </li>
                <li className='p-5'>
                    <Link to="/about" className='text-white text-thin'>About</Link>
                </li>
                <li className='p-5'>
                    <Link to="/challenges" className='text-white text-thin'>Challenges</Link>
                </li>
                <li className='p-5'>
                    <Link to="/sponsorship" className='text-white text-thin'>Sponsorship</Link>
                </li>
                {/* <li className='p-5'>
                <Link to="/resources" className='text-white text-thin'>Resources</Link>
                </li>
              
                <li className='p-5 pt-6'>
                <Link to="/placeholder" className='text-white text-thin text-xl'><FaSearch/></Link>
                </li> */}
                <li className='p-5'>
                    <AnimatedModal createNewUser={props.createNewUser} updateNewUser={props.updateNewUser} setUserFromLogin={props.setUserFromLogin} authTest={props.authTest} {...props} />
                </li>
            </ul>
        )
    }
}

export default NavigationRes;
