import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, EmailIcon } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Allocation from "./Allocation";
import Criteria from "./Criteria";
import { useEffect, useState } from "react";
import axios from "axios";
import { Divider } from "@material-ui/core";
import { useContext } from "react";
import { AlertContext } from "../Globals/Hooks/AlertContext";

function Details(props) {
  let image_url = process.env.REACT_APP_API_URL.replace("/api", "");
  const url = window.location.href;

  const { setAlert } = useContext(AlertContext);

  const onClick = () => {
    setAlert("Copied to Clipboard", "success");
  };

  let [state, setState] = useState({
    headers: null,
  });

  const getCriteria = async (challenge_id) => {
    return await axios.get("criteria/" + challenge_id).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    Promise.all([getCriteria(props.challenge.id)]).then((res) => {
      if (res[0] && res[0].data && Object.keys(res[0].data).length !== 0) {
        setState({
          headers: res[0] ? res[0].data : null,
        });
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div className="xl:flex md:block w-4/5 m-auto">
        <h1 className="w-4/12 p-10 pl-0 pr-0 pb-0 text-5xl font-thin border-b">
          {props.challenge.name}
        </h1>
        <div className="w-full text-center bg-gray-100">
          <span className="sm:w-auto md:w-3/5 p-8 pb-0 m-auto justify-center text-3xl text-makeblue font-medium xl:flex md:flex sm:block">
            <CopyToClipboard text={url}>
              <button onClick={onClick}>SHARE THIS CHALLENGE</button>
            </CopyToClipboard>
            <div className="flex text-4xl px-10">
              <span className="px-3">
                <FacebookShareButton url={url}>
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
              </span>
              <span className="px-3">
                <TwitterShareButton url={url}>
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
              </span>
              <span className="px-3">
                <EmailShareButton url={url}>
                  <EmailIcon size={32} round={true} />
                </EmailShareButton>
              </span>
            </div>
          </span>
        </div>
      </div>

      <div className="w-4/5 m-auto pt-8">
        <h1 className="text-3xl font-medium">Problem Definition</h1>
        <Divider />
        <span id="details" className="w-4/5 font-thin text-2xl text-gray-700">
          {props.challenge.description}
        </span>
      </div>

      <div className="w-4/5 m-auto pt-8">
        <h1 className="text-3xl font-medium">Requirements</h1>
        <Divider />
        <span id="includes" className="w-4/5 font-thin text-2xl text-gray-700">
          <ul>
            {props.challenge.requirements &&
              props.challenge.requirements.map((r) => {
                return <li>{r.title}</li>;
              })}
          </ul>
        </span>
      </div>

      {state.headers && (
        <div className="w-4/5 m-auto pt-8">
          <h1 className="text-3xl font-medium">Criteria</h1>
          <Divider />
          <span
            id="criteria"
            className="w-4/5 font-thin text-2xl text-gray-700"
          >
            <Criteria headers={state.headers} />
          </span>
        </div>
      )}

      <div className="flex w-4/6 m-auto">
        {props.challenge.example_image_1_url && (
          <img
            className="w-1/3 h-auto mt-10 mx-2 border rounded-md object-cover"
            src={image_url + props.challenge.example_image_1_url}
            alt="test"
          />
        )}
        {props.challenge.example_image_2_url && (
          <img
            className="w-1/3 h-auto mt-10 mx-2 border rounded-md object-cover"
            src={image_url + props.challenge.example_image_2_url}
            alt="test"
          />
        )}
        {props.challenge.example_image_3_url && (
          <img
            className="w-1/3 h-auto mt-10 mx-2 border rounded-md object-cover"
            src={image_url + props.challenge.example_image_3_url}
            alt="test"
          />
        )}
      </div>

      <div className="w-4/6 m-auto">
        <Allocation challenge={props.challenge || {}} />
      </div>
    </React.Fragment>
  );
}

export default Details;
