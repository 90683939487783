import React, { Component } from 'react'
import Card from './Card'
import Challenge from './Challenge'
import Works from './Works'
import Collaboration from './Collaboration'
import Started from './Started'
import { IoIosArrowDown } from "react-icons/io";
import axios from 'axios'
import Dropdown from 'react-bootstrap/Dropdown'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Divider, FormControlLabel, FormGroup, withStyles } from '@material-ui/core'
import M4Switch from '../Globals/CommonComponents/M4Switch.jsx'

class Home extends Component {

    state = {
        Card: false,
        Challenges: [],
        companies: [],
        newUser: {},
        loadMoreChallengesNum: 8,
        isLoading: false,
        expected: 0,
        sortType: 'desc',
        sortCat: 'prize_amount',
        reRender: false,
        active: true
    }

    constructor(props) {
        super(props);
        this.getChallenges(true);
        this.getCompanies();

    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.reRender;
    }

    getChallenges = (key) => {
        let query = this.getQueryString(key);
        axios.get('/challenge' + (query))
            .then(res => {
                let updatedChallenges = res.data.data;
                this.setState({
                    ...this.state,
                    Challenges: updatedChallenges,
                    isLoading: true,
                    reRender: true
                });
            }).catch((err) => {
                console.log(err);
            });
    }

    getCompanies = () => {
        axios.get('/company')
        .then(res => {
            let currState = this.state;
            let newState = {
                ...currState,
                companies: res.data.data
            };
            this.setState(newState);
        }).catch((err) => {
            console.log(err);
        });
    }

    getQueryString = (key) => {
        let query = "?records=";
        let comparator = this.state.Challenges.length < this.state.expected ? this.state.expected : this.state.Challenges.length;
        let expected = comparator + (key ? this.state.loadMoreChallengesNum : 0);
        expected = expected < 4 ? 4 : expected;
        query = query + expected;
        query = query + "&active=" + this.state.active;
        query = query + "&sort_cat=" + this.state.sortCat + "&sort_type=" + this.state.sortType;
        this.setState({ expected: expected });
        return query;
    }
    resetShowChallenges = () => {
        let updatedChallenges = this.state.Challenges.map((challenge, index) => {

            challenge.show_challenge = 0;
            return challenge;
        })
        this.setState({ Challenges: updatedChallenges });

    }

    setSort = (category) => {
        let type = 'asc';
        if (this.state.sortType === type) {
            type = 'desc';
        }
        this.setState({
            sortCat: category,
            sortType: type
        }, () => {
            this.getChallenges(false)
        });
    }

    filterActive = () => {
        const oldState = this.state;
        this.setState({
            ...oldState,
            active: !oldState.active,
        }, () => {
            this.getChallenges(false)
        });
    }

    render() {
        let cards = this.state.Challenges;
        if (!cards) return (null);

        return (

            <React.Fragment>
                <Challenge />
                <div className="container m-auto px-7">
                    <div className='flex mb-4'>
                        <div className='xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full m-auto flex items-center'>
                            <div>
                                <a href='/challenges'>
                                    <button className='focus:outline-none focus:shadow-outline p-2 px-10 text-gray-600 shadow text-base border border-blue-500 rounded-md'>ALL CHALLENGES</button>
                                </a>
                            </div>
                            <FormGroup className='mx-4'>
                                <FormControlLabel control={<M4Switch checked={this.state.active} color='primary' onChange={this.filterActive} name="Active"/>} label="Active"></FormControlLabel>
                            </FormGroup>
                        </div>
                        <div className='xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full w-full'>
                            <Dropdown className='text-right'>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className='w-auto text-right text-blue-500 text-xl hover:text-black focus:outline-none active:outline-none'>
                                    Sort By<IoIosArrowDown className='inline-block' />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='drop-down-rounded flex flex-col bg-gray-300 z-10 text-left'>
                                    <Dropdown.Item className={this.state.sortCat === 'prize_amount' ? 'p-2 switch-on radius-none' : 'p-2 switch-off'}
                                        onClick={(e) => this.setSort('prize_amount')} href="#/action-2">Prize Amount {this.state.sortCat === 'prize_amount' && this.state.sortType === 'desc' ? <ExpandLessIcon /> : ''} {this.state.sortCat === 'prize_amount' && this.state.sortType === 'asc' ? <ExpandMoreIcon /> : ''}</Dropdown.Item>
                                    <Dropdown.Item className={this.state.sortCat === 'remaining_days' ? 'p-2 switch-on radius-top' : 'p-2 switch-off'}
                                        onClick={(e) => this.setSort('remaining_days')} href="#/action-1">Remaining Days {this.state.sortCat === 'remaining_days' && this.state.sortType === 'desc' ? <ExpandLessIcon /> : ''} {this.state.sortCat === 'remaining_days' && this.state.sortType === 'asc' ? <ExpandMoreIcon /> : ''} </Dropdown.Item>
                                    <Dropdown.Item className={this.state.sortCat === 'alphabetic' ? 'p-2 switch-on radius-bottom' : 'p-2 switch-off'}
                                        onClick={(e) => this.setSort('alphabetic')} href="#/action-3">Alphabetically {this.state.sortCat === 'alphabetic' && this.state.sortType === 'desc' ? <ExpandLessIcon /> : ''} {this.state.sortCat === 'alphabetic' && this.state.sortType === 'asc' ? <ExpandMoreIcon /> : ''}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="container m-auto px-4">
                    {this.state.isLoading === false &&
                        <React.Fragment>
                            <div className="text-center">
                                <div className='loadding-icon'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        </React.Fragment>
                    }
                    <div className='grid lg:grid-cols-4 md:grid-cols-2 gap-4 justify-center'>
                        {cards.map((cards, index) => {
                            return (
                                <Card data={cards} key={index} companies={this.state.companies} />
                            )
                        })
                        }
                    </div>
                    <div className="md:flex md-flex-wrap md:-mx-4">
                        <div className='m-auto w-4/5 text-center'>
                            <Divider className={this.props.classes.divider} />
                            <Button 
                                disabled={this.state.expected > this.state.Challenges.length}
                                className={`${this.props.classes.button} font-paragraph`}
                                onClick={() => {
                                    this.getChallenges(true);
                                }}
                            >
                                {this.state.expected > this.state.Challenges.length ? "End of List" : "Load More"}
                            </Button>
                        </div>
                    </div>
                </div>
                <React.Fragment>
                    <Works />
                </React.Fragment>

                <div className='pb-10'>
                    <Collaboration />
                </div>

                <React.Fragment>
                    <Started user={this.props.user} updateNewUser={this.props.updateNewUser} setUserFromLogin={this.props.setUserFromLogin} createNewUser={this.props.createNewUser} />
                </React.Fragment>
            </React.Fragment>
        )
    }
};

const styles = (theme) => ({
    root: {
    },
    divider: {
        'margin-top': '1rem',
        'margin-bottom': '1rem'
    },
    button: {
        'margin-bottom': '2rem'
    }
});

export default withStyles(styles)(Home);