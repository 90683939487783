import React from 'react'

const Privacy = () => {
    return (
        <div className='text-center text-2xl pt-12 w-full m-auto  font-hairline'>
            <div className='xl:w-auto lg:w-auto m-auto'>
            <h1 className='text-5xl font-thin'>
                     Make48:Privacy
            </h1>
            <span className='w-5/6 m-auto grid'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tincidunt augue interdum velit euismod in pellentesque massa placerat duis. Ut porttitor leo a diam sollicitudin tempor id eu. Sed adipiscing diam donec adipiscing. Senectus et netus et malesuada fames ac turpis egestas integer. At lectus urna duis convallis. Tincidunt nunc pulvinar sapien et ligula ullamcorper malesuada proin. Nisl purus in mollis nunc. Tortor condimentum lacinia quis vel eros donec ac odio. Integer quis auctor elit sed vulputate mi. Congue mauris rhoncus aenean vel elit scelerisque. Adipiscing commodo elit at imperdiet dui accumsan sit amet nulla. Volutpat odio facilisis mauris sit amet. Velit euismod in pellentesque massa placerat duis.
    
                Vestibulum lorem sed risus ultricies tristique nulla aliquet enim tortor. Tincidunt nunc pulvinar sapien et. Molestie a iaculis at erat pellentesque adipiscing commodo elit at. Mus mauris vitae ultricies leo integer malesuada nunc vel. Aliquet enim tortor at auctor urna nunc id. Nunc sed velit dignissim sodales ut eu sem integer vitae. Dolor sed viverra ipsum nunc aliquet bibendum enim. Diam maecenas sed enim ut sem. Aliquam id diam maecenas ultricies. Eget dolor morbi non arcu. Id volutpat lacus laoreet non curabitur gravida arcu ac. Urna id volutpat lacus laoreet non curabitur gravida arcu ac. Consequat id porta nibh venenatis cras sed felis. Adipiscing elit duis tristique sollicitudin nibh sit amet. Hendrerit gravida rutrum quisque non tellus orci ac.

                Est ante in nibh mauris cursus mattis. 
         
    
          
                Lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare.
                 Purus in massa tempor nec feugiat nisl pretium.
                Vitae congue mauris rhoncus aenean vel elit.  
          
                Consequat semper viverra nam libero justo laoreet. Congue mauris rhoncus aenean vel elit scelerisque mauris. Sed libero enim sed faucibus turpis. Nibh ipsum consequat nisl vel. Parturient montes nascetur ridiculus mus mauris. Eget egestas purus viverra accumsan in nisl nisi scelerisque eu. Odio tempor orci dapibus ultrices in. Posuere lorem ipsum dolor sit amet consectetur adipiscing.

            
                Ornare arcu odio ut sem nulla pharetra diam. Amet tellus cras adipiscing enim. Fermentum et sollicitudin ac orci phasellus. Eu tincidunt tortor aliquam nulla. Eget duis at tellus at urna condimentum mattis. Nulla facilisi etiam dignissim diam quis enim. Amet facilisis magna etiam tempor orci eu lobortis. Aliquam faucibus purus in massa tempor nec feugiat nisl. Lorem ipsum dolor sit amet consectetur. Tellus molestie nunc non blandit massa enim nec dui nunc. Ultrices vitae auctor eu augue. Condimentum lacinia quis vel eros donec ac.
                At ultrices mi tempus imperdiet nulla. Orci porta non pulvinar neque laoreet. Gravida neque convallis a cras semper auctor neque. Pulvinar etiam non quam lacus suspendisse faucibus interdum. Laoreet sit amet cursus sit. Neque ornare aenean euismod elementum nisi. Venenatis cras sed felis eget velit aliquet sagittis id consectetur. Volutpat ac tincidunt vitae semper. Sit amet justo donec enim diam vulputate. Volutpat est velit egestas dui. Non nisi est sit amet facilisis magna etiam tempor orci. Semper quis lectus nulla at volutpat diam ut venenatis. Augue neque gravida in fermentum et sollicitudin ac orci. Risus feugiat in ante metus dictum at tempor commodo ullamcorper. Molestie ac feugiat sed lectus vestibulum mattis. Faucibus purus in massa tempor nec feugiat. Et magnis dis parturient montes nascetur ridiculus mus mauris vitae. Vulputate odio ut enim blandit volutpat maecenas. Ultrices mi tempus imperdiet nulla malesuada pellentesque. Pulvinar mattis nunc sed blandit libero volutpat sed.
                Venenatis a condimentum vitae sapien pellentesque.
         
    
    
            
            Arcu non odio euismod lacinia at quis risus. Donec enim diam vulputate ut pharetra sit amet aliquam id. Morbi tincidunt augue interdum velit euismod in. Ultrices gravida dictum fusce ut placerat orci nulla. Et magnis dis parturient montes nascetur ridiculus. Tristique senectus et netus et malesuada fames. Odio ut enim blandit volutpat maecenas volutpat blandit aliquam. Feugiat sed lectus vestibulum mattis ullamcorper velit sed. Elit ullamcorper dignissim cras tincidunt lobortis feugiat. Vulputate dignissim suspendisse in est ante in nibh mauris cursus.
         
    
            </span>
            </div>
        </div>

    )
}

export default Privacy;
