import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import axios from "axios"

const Signin = (props) => {
  let validationSchema = yup.object().shape({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    email: yup.string().email("Email Must be Valid").required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  const Signup = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      props.updateNewUser(values)
      values.role_id = 2 //set default to user role=2
      axios
        .post("/user", values)
        .then((res) => {
          let newUserObject = {
            access_token: res.data,
            user: values,
          }
          props.createNewUser(newUserObject)
        })
        .catch((err) => {
          // console.log(err)
        })
    },
    validationSchema,
  })
  return (
    <div className="w-full bg-gray-100">
      <div className="xl:w-3/5 sm:w-auto m-auto text-center p-10 mb-10">
        <h1 className="text-5xl font-thin"> See this Challenge in DETAIL </h1>
        <span className="text-2xl font-thin xl:w-1/5 sm:w-auto m-auto">
          To see the rest of this challenge, get up and running with Make48. Sign up now. Start or join a challenge and help build the future.
        </span>
      </div>

      <React.Fragment>
        <div className="flex w-full p-5 m-auto justify-center bg-makeblue">
          <form className="xl:flex sm:block m-auto justify-center w-4/5 flex-wrap" onSubmit={Signup.handleSubmit}>
            <div className='relative w-1/6 m-2'>
              <input
                onChange={Signup.handleChange}
                value={Signup.values.first_name}
                className=" rounded-lg bg-transparent opacity-50 p-3 block text-white placeholder-white border-solid border-2 border-opacity-25"
                type="first_name"
                id="first_name"
                placeholder="First Name"
              ></input>
              {Signup.touched.first_name && Signup.errors.first_name ? (
                <div className=" text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{Signup.errors.first_name}</div>
              ) : null}
            </div>
            <div className='relative w-1/6 m-2'>
              <input
                onChange={Signup.handleChange}
                value={Signup.values.last_name}
                className="rounded-lg bg-transparent opacity-50 p-3 block text-white placeholder-white border-solid border-2 border-opacity-25"
                type="last_name"
                id="last_name"
                placeholder="Last Name"
              ></input>
              {Signup.touched.last_name && Signup.errors.last_name ? (
                <div className=" text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{Signup.errors.last_name}</div>
              ) : null}
            </div>
            <div className='relative w-1/6 m-2'>
              <input
                onChange={Signup.handleChange}
                value={Signup.values.email}
                className="rounded-lg bg-transparent opacity-50 p-3 block text-white placeholder-white border-solid border-2 border-opacity-25"
                type="email"
                id="email"
                placeholder="E-mail"
              ></input>
              {Signup.touched.email && Signup.errors.email ? <div className=" text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{Signup.errors.email}</div> : null}
            </div>
            <div className='relative m-2 w-1/6'>
              <input
                onChange={Signup.handleChange}
                value={Signup.values.password}
                className="rounded-lg bg-transparent opacity-50 p-3 block text-white placeholder-white border-solid border-2 border-opacity-25"
                type="password"
                id="password"
                placeholder="Password"
              ></input>
              {Signup.touched.password && Signup.errors.password ? <div className=" text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{Signup.errors.password}</div> : null}
            </div>
            <div className='w-1/6 relative'>
              <button className="w-full m-2 p-3 bg-button-green text-base shadow rounded-lg" type="submit">
                Sign up
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    </div>
  )
}
export default Signin
