import React from 'react'
import { useState } from 'react';
import { createContext } from 'react';

const initialState = {
    user: {}
};

export const UserContext = createContext({
    ...initialState,
    setUserContext: () => {}
});

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const setUserContext = (user) => {
        setUser(user);
    };

    return (
        <UserContext.Provider
            value={{
                user,
                setUserContext
            }}
        >
            {children}
        </UserContext.Provider>
    )
}