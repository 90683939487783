import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import axios from "axios"

const Signupmodal = (props) => {
  let validationSchema = yup.object().shape({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    email: yup.string().email("Email Must be Valid").required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  const Signup = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      props.updateNewUser(values)
      values.role_id = 2 //set default to user role=2
      axios
        .post("/user", values)
        .then((res) => {
          let newUserObject = {
            access_token: res.data,
            user: values,
          }
          props.createNewUser(newUserObject)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    validationSchema,
  })
  return (
    <div className="bg-makeblue text-white">
      <div className="w-full m-auto xl:flex sm:block">
        <div className="w-full">
          <h1 className="p-5 text-5xl text-center font-thin">Get Started Now</h1>

          <form className="justify-center w-full" onSubmit={Signup.handleSubmit}>
            <div className="my-5">
              <input
                onChange={Signup.handleChange}
                value={Signup.values.first_name}
                className="w-4/5 m-auto rounded-lg bg-makebluetwo block p-3"
                type="first_name"
                id="first_name"
                placeholder="First Name"
              ></input>
              {Signup.touched.first_name && Signup.errors.first_name ? (
                <div className="w-4/5 m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{Signup.errors.first_name}</div>
              ) : null}
            </div>
            <div className="my-5">
              <input
                onChange={Signup.handleChange}
                value={Signup.values.last_name}
                className="w-4/5 m-auto rounded-lg bg-makebluetwo block p-3"
                type="last_name"
                id="last_name"
                placeholder="Last Name"
              ></input>
              {Signup.touched.last_name && Signup.errors.last_name ? (
                <div className="w-4/5 m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{Signup.errors.last_name}</div>
              ) : null}
            </div>

            <div className="my-5">
              <input
                onChange={Signup.handleChange}
                value={Signup.values.email}
                className="w-4/5 m-auto rounded-lg bg-makebluetwo block p-3"
                type="email"
                id="email"
                placeholder="Email"
              ></input>
              {Signup.touched.email && Signup.errors.email ? <div className="w-4/5 m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{Signup.errors.email}</div> : null}
            </div>

            <div className="my-5">
              <input
                onChange={Signup.handleChange}
                value={Signup.values.password}
                className="w-4/5 m-auto rounded-lg bg-makebluetwo block p-3"
                type="password"
                id="password"
                placeholder="Password"
              ></input>
              {Signup.touched.password && Signup.errors.password ? (
                <div className="w-4/5 m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{Signup.errors.password}</div>
              ) : null}
            </div>

            <div className="w-4/5 m-auto text-center">
              <button className="p-4 mb-2 bg-button-green shadow text-base rounded-lg" type="submit">
                Sign up
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Signupmodal
