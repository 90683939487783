import React, { useState } from 'react'
import Navigation from './Navigation'
import Logo from '../Assets/m48-bw.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import MediaQuery from "react-responsive";
import NavigationRes from './NavigationRes'

function Header(props) {
    const [showMenu, setShowMenu] = useState(false)
    let menu

    if (showMenu) {
        menu =
            <div className='bg-transparent h-full block'>
                <NavigationRes createNewUser={props.createNewUser} updateNewUser={props.updateNewUser} setUserFromLogin={props.setUserFromLogin} authTest={props.authTest} user={props.user} getCompanies={props.getCompanies} />
            </div>
    }
    return (


        <header className=" pt-2 w-full m-auto block lg:flex justify-between items-center bg-makeblue relative">
            <a href='/'>
                <img src={Logo} alt='logo' className='lg:w-auto max-h-20 w-2/6 xl:ml-10 ml-5'></img>
            </a>

            <MediaQuery query="(max-width: 992px)">
                <FontAwesomeIcon
                    icon={faBars}
                    onClick={() => setShowMenu(!showMenu)} />
                    {menu}
            </MediaQuery>


            <MediaQuery query="(min-width: 1024px)">
                <Navigation createNewUser={props.createNewUser} updateNewUser={props.updateNewUser} setUserFromLogin={props.setUserFromLogin} authTest={props.authTest} user={props.user} getCompanies={props.getCompanies}{...props}></Navigation>
            </MediaQuery>

        </header >
    )
};

export default Header;