import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Submission from './Submission';
import Projdescription from './Projdescription';


const useStyles = makeStyles((theme) => ({

  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100ch',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

const MultiselectProject = (props) => {

  const classes = useStyles();
  const [checkedRequirements, setCheckedRequirements] = React.useState([]);

  const handleChange = (event) => {
    setCheckedRequirements(event.target.value);
    if (event.target.name === 'project_requirements') {
      props.updateNewChallenge({requirements: event.target.value});
    }
  }

  return (
    <div className='m-auto w-full'>
      <div className='xl:flex sm:block'>
        <FormControl className={classes.formControl}>
          <InputLabel id="project_requirements">Requirements for Final Project</InputLabel>
          <Select
            labelId="project_requirements"
            id="project_requirements"
            multiple
            value={checkedRequirements}
            renderValue={(selected) => selected.length !== 1 ? "Multiple" : props.requirements.find(req => req.id === selected[0]).title}
            MenuProps={MenuProps}
            onChange={handleChange}
            name='project_requirements'
          >
            {props.requirements.map((project_requirements) => (
              <MenuItem key={project_requirements.id} value={project_requirements.id}>
                <Checkbox checked={checkedRequirements.includes(project_requirements.id)} />
                <ListItemText primary={project_requirements.title} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <div className=''>
          <Submission render={{ props }} handleChange={props.handleChange} updateNewChallenge={props.updateNewChallenge} {...props} />
        </div>
      </div>

      {/* <div className='my-10'>
        <Partnership render={{ props }} handleChange={props.handleChange} updateNewChallenge={props.updateNewChallenge} {...props} />
      </div> */}

      <div>
        <Projdescription render={{ props }} errors={props.errors} handleChange={props.handleChange} updateNewChallenge={props.updateNewChallenge} {...props} />
      </div>
    </div>


  );
}
export default MultiselectProject