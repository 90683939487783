import React, { Component } from 'react'
import LeaderThree from '../Assets/Userpic-2.png'

export class Thirdplace extends Component {
    render() {
        return (
            <div className='block'>

                <figure className='flex rounded-full border shadow p-3 m-auto xl:mx-10 sm:mx-auto mt-10 object-contain figureThree'>                        
                    <img className='thirdWinner' src={LeaderThree} alt='test'/>
                </figure>

                <div className='placeNumbers flex xl:w-3/12 sm:w-1/12 py-4 flex-col border bg-leaderBrown text-white text-xl m-auto xl:mt-20 md:mt-2 sm:mt-2'>3

                </div>

            </div>
        )
    }
}

export default Thirdplace
