import { Snackbar } from '@material-ui/core';
import { CheckCircleOutline, ErrorOutline, InfoOutlined } from '@material-ui/icons';
import React, { useContext } from 'react';
import { AlertContext } from '../Hooks/AlertContext';

function M4Alert (props) {
    const {message, type, open} = useContext(AlertContext);

    let icon = null;

    if(type === 'success') {
        icon = (<CheckCircleOutline className='text-emerald-400 mr-1'/>);
    }else if(type === 'info') {
        icon = (<InfoOutlined className='text-sky-400 mr-1' />);
    }else if(type === 'error') {
        icon = (<ErrorOutline className=' text-red-700 mr-1' />);
    }

    return (
        <Snackbar 
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open={open}
        >
            <div className='bg-neutral-100 p-3 rounded-md'>
                {icon}
                {message}
            </div>
        </Snackbar>
    );
}

export default M4Alert;