import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react'

const styles = {
    root: {
        color: 'rgb(109,179,237)'
    }
};

const M4LoaderInternal = (props) => {
    return (
        <CircularProgress 
            className={props.classes.root}
            {...props}
        />
    );
};

export default withStyles(styles)(M4LoaderInternal);