import { Divider, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

const CriteriaGroup = (props) => {
    const classes = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 300,
            maxWidth: 500,
        },
        addCriteriaButton: {
            height: "fit-content"
        }
    }))();

    return(
        props.headers.length > 0 && props.headers.map((header, headerIndex) => {
            return (
                <>
                <Grid container>
                    <Grid md='auto'>
                        <FormControl className={classes.formControl}>
                            <InputLabel id='header-select-label'>Criteria Header</InputLabel>
                            <Select
                                labelId='header-select-label'
                                id={`header-select-${headerIndex}`}
                                value={props.headers.find(h => h.id === header.id)?.value}
                                onChange={(e) => props.onUpdateCriteriaHeader(e.target.value, header.id)}
                                label='header-select-label'
                            >
                                {props.entity.length > 0 && props.entity
                                    // .filter((baseHeader) => !props.headers.some(h => h.value === baseHeader.id))
                                    .map((baseHeader) => {
                                    return (
                                        <MenuItem
                                            value={baseHeader.id}
                                        >
                                            {baseHeader.header}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid md>
                        <Grid container direction='column'>
                            {props.criteria.length > 0 && props.criteria
                                .filter((c) => c.headerId === header.value)
                                .map((criteria, criteriaIndex) => {
                                    return (
                                        <Grid>
                                            <FormControl>
                                                <TextField
                                                    id={`criteria-field-${headerIndex}-${criteriaIndex}`}
                                                    multiline
                                                    maxRows={7}
                                                    label='Criteria'
                                                    onChange={(e) => props.onUpdateCriteria(e.target.value, criteria.id)}
                                                    value={props.criteria.find(c => c.id === criteria.id)?.value} />
                                            </FormControl>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </Grid>
                    <Button
                        className={`${classes.addCriteriaButton} w-1/5 p-3 mt-5 bg-makeblue rounded submit-hover delay-75 h-5`}
                        onClick={() => props.onAddCriteria(props.headers.find(h => h.id === header.id)?.value)}
                    >Add Criteria</Button>
                </Grid><Divider></Divider></>
            );
        })
    );
};

const Criteria = (props) => {

    const {entity} = props;
    const [headers, setHeaders] = useState([]);
    const [criteria, setCriteria] = useState([]);

    const handleAddCriteriaHeader = () => {
        if(!headers.some(h => h.value === undefined)){
            let newHeaders = headers.concat([{id: headers.length}]);
            setHeaders(newHeaders);
        }
    }

    const handleAddCriteria = (headerId) => {
        if(headers.find(h => h.value === headerId)?.value !== undefined){
            let newCriteria = criteria.concat([{id: criteria.length, criteria: "", headerId: headerId}]);
            setCriteria(newCriteria);
        }
    }

    const handleUpdateCriteriaHeader = (value, id) => {
        const newHeaders = headers.map(h => {
            if(h.id === id){
                let newHeader = h;
                newHeader.value = value;
                return newHeader;
            }
            return h;
        });
        setHeaders(newHeaders);
    };

    const handleUpdateCriteria = (value, id, ) => {
        const newCriteria = criteria.map(c => {
            if(c.id === id){
                let newCrit = c;
                newCrit.criteria = value.toString();
                return newCrit;
            }
            return c;
        });
        setCriteria(newCriteria);
        props.onUpdateCriteria({criteria: newCriteria});
    };

    return (
        <>
            <CriteriaGroup 
                headers={headers} 
                criteria={criteria} 
                entity={entity}
                onAddCriteria={handleAddCriteria}
                onUpdateCriteriaHeader={handleUpdateCriteriaHeader}
                onUpdateCriteria={handleUpdateCriteria}
            />
            <Button
                className='w-1/5 h-3 p-3 mt-5 bg-makeblue rounded submit-hover delay-75'
                onClick={() => handleAddCriteriaHeader()}
            >
                Add Header
            </Button>
            <Divider/>
        </>
    );
}

export default Criteria;