import React from 'react';
import { createTheme, ThemeProvider } from "@material-ui/core";

const globalTheme = createTheme({
    palette: {
        primary: {
            main: 'rgb(109,179,237)'
        },
        secondary: {
            main: 'rgb(89,138,179)'
        },
        success: {
            main: 'rgb(141,198,66)'
        }
    }
});

function Theme (props) {
    return (
        <ThemeProvider theme={globalTheme}>
            {props.children}
        </ThemeProvider>
    );
}

export default Theme;