import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 500,
    maxWidth: 500,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

const categories = [
  "Interactive Outdoor Play",
  "Sustainable Mobility",
  "Winter Outdoor Activities",
  "Recycling Solar Panels",
  "The Great Outdoors",
  "Construction",
];

const Multiselect = (props) => {
  const classes = useStyles();
  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    setPersonName(event.target.value);
    if (event.target.name === "categories") {
      props.updateNewChallenge({ category: event.target.value.toString() });
    }
  };

  return (
    <div className="my-10">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/2 px-4">
          <FormControl className={classes.formControl}>
            <InputLabel id="category_selector">Categories</InputLabel>
            <Select
              labelId="category_selector"
              id="category_selector"
              multiple
              value={personName}
              input={<Input />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              onChange={handleChange}
              name="categories"
              error={props.error}
            >
              {categories.map((choices) => (
                <MenuItem key={choices} value={choices}>
                  <Checkbox checked={personName.indexOf(choices) > -1} />
                  <ListItemText primary={choices} />
                </MenuItem>
              ))}
            </Select>
            {props.error && (
              <div class="w-full m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">
                Category is required
              </div>
            )}
          </FormControl>
        </div>
      </div>
    </div>
  );
};
export default Multiselect;
