import React, {useState} from 'react'
import Hero from '../ChallengePage/Comphero'
import Signin from '../ChallengePage/Reqsignup'
import axios from 'axios'
import {
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton,
} from "react-share";
import {
    FacebookIcon,
    TwitterIcon,
    EmailIcon
} from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useContext } from 'react';
import { AlertContext } from '../Globals/Hooks/AlertContext';
import { Divider } from '@material-ui/core';
import M4Loader from '../Globals/CommonComponents/M4Loader';

const Signup = (props) => {

    const [challenge, setChallenge] = useState(null);
    const url = window.location.href;

    const { setAlert } = useContext(AlertContext);

    const onClick = () => {
        setAlert('Copied to Clipboard', 'success');
    }
    
    let challenge_id = props.match.params.id;
    if (!challenge && challenge_id) {
        // make call to get challenge info from back end
        axios.get('/challenge/'+challenge_id)
            .then(res => {
                setChallenge(res.data.data)
            }).catch((err) => {
                console.log(err);
            });
    }

    if(!challenge && challenge_id){
        return <M4Loader />;
    }

    return (
    <div className='w-full'>
        <React.Fragment>
            <Hero challenge={challenge || {}} company={props.company}/>
        </React.Fragment>

        <React.Fragment>
        <div className='w-4/5 m-auto py-8'>
            <h1 className='text-4xl font-medium'>Problem Definition</h1>
            <Divider/>
            <span id='details' className='w-4/5 font-thin text-3xl text-gray-700'>{challenge.description}</span>
        </div>
        <div className='w-full text-center bg-gray-100'>
            <span className='sm:w-auto md:w-3/5 p-8 pb-0 m-auto justify-center text-4xl text-makeblue font-medium xl:flex md:flex sm:block'>
                <CopyToClipboard text={url}>
                    <button onClick={onClick}>SHARE THIS CHALLENGE</button>
                </CopyToClipboard>
                <div className='flex text-4xl px-10'>
                    <span className='px-3'>
                        <FacebookShareButton url={url}>
                            <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                    </span>
                    <span className='px-3'>
                        <TwitterShareButton url={url}>
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                    </span>
                    <span className='px-3'>
                        <EmailShareButton url={url}>
                            <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                    </span>
                </div>
            </span>
        </div>
                
        <Signin user={props.user} createNewUser={props.createNewUser} updateNewUser={props.updateNewUser} {...props}/>
        </React.Fragment>
    </div>
    )
}
export default Signup