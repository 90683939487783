import React, { useState } from 'react';
import './index.css';
import Header from './Components/Globals/Header';
import Footer from './Components/Globals/Footer'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Competition from './Components/LoggedIn/Challengeloggedin';
import Home from './Components/Home/Home'
import Admindash from './Components/Dashboards/Admindash'
import Userdash from './Components/Dashboards/UserDashboard/Userdash'
import axios from 'axios'
import NavChallenges from './Views/NavChallenges'
import Terms from './Components/Globals/Terms';
import Privacy from './Components/Home/Privacy';
import ResetPassword from './Components/Password/ResetPassword';
import M4Alert from './Components/Globals/CommonComponents/M4Alert';
import { AlertContext } from './Components/Globals/Hooks/AlertContext';
import { useContext } from 'react';
import BioPage from './Components/Bio/Bio';
import Theme from './Components/Globals/Theme';
import { UserContext } from './Components/Globals/Hooks/UserContext';

const App = () => {
  const [setLoading] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [companies, setCompanies] = useState();

  const { setAlert } = useContext(AlertContext);
  const { user, setUserContext } = useContext(UserContext);

  const authTest = () => {
    if (user) {
      return;
    }

    const loadUser = async () => {
      // Is user currently authorized?
      return await axios.post('/me')
      .then(res => {
          return res.data;
      })
      .catch((err) => {
          return null;
      });
    };

    Promise.all([loadUser()])
    .then((res) => {
      if(res[0] !== null){
        setUserContext(res[0]);
      }
    }).catch(() => {});
  }

  const updateNewUser = (newData) => {
    setNewUser(newData);
  }

  const createNewUser = async (newUserObject) => {
    setUserContext(newUserObject)
  }

  const setUserFromLogin = async (userData) => {
    if(userData.access_token.message === "fail") {
      setAlert(userData.access_token.data, 'success');
    }
    else{
      localStorage.setItem('access_token', userData.access_token.data ? userData.access_token.data : userData.access_token);
      setUserContext(userData.user);
    }

    if (userData.user.role_id === 1) {
      setRedirect('/admin-dashboard')
    } else {
      setRedirect('/')
    }
  }

  const toggleLoading = (loading) => {
    // setLoading(loading);
  }

  authTest();

  return (
    
      <Theme>
        <Router path='/'>
          {/* <Redirect to={redirect} /> */}

          <Header createNewUser={createNewUser} updateNewUser={updateNewUser} setUserFromLogin={setUserFromLogin} authTest={authTest} user={user}></Header>
          <M4Alert />
          <Switch>
            <Route exact path="/" render={(props) => <Home user={user} toggleLoading={toggleLoading} setUserFromLogin={setUserFromLogin} createNewUser={createNewUser} updateNewUser={updateNewUser} {...props} />} />
            <Route exact path="/challenges" render={(props) => <NavChallenges setUserFromLogin={setUserFromLogin} user={user} toggleLoading={toggleLoading} createNewUser={createNewUser} updateNewUser={updateNewUser} {...props} />} />
            <Route exact path="/challenge/:id" render={(props) => <Competition setUserFromLogin={setUserFromLogin} user={user} toggleLoading={toggleLoading} createNewUser={createNewUser} updateNewUser={updateNewUser} {...props} />} />
            <Route exact path="/placeholder" render={(props) => <Competition user={user} toggleLoading={toggleLoading} {...props} />} />
            <Route exact path="/admin-dashboard" render={(props) => <Admindash user={user} toggleLoading={toggleLoading} {...props} />} />
            <Route exact path="/user" render={(props) => <Userdash toggleLoading={toggleLoading} companies={companies} {...props} />} />
            <Route exact path="/terms" render={(props) => <Terms user={user} toggleLoading={toggleLoading} companies={companies} {...props} />} />
            <Route exact path="/privacy" render={(props) => <Privacy user={user} toggleLoading={toggleLoading} companies={companies} {...props} />} />
            <Route path="/password/reset" render={(props) => <ResetPassword user={user} toggleLoading={toggleLoading} companies={companies} {...props} />} />
            <Route exact path="/bio/:id" render={(props) => <BioPage user={user} toggleLoading={toggleLoading} {...props} />} />
          </Switch>
        </Router>

        <React.Fragment>
          <Footer />
        </React.Fragment>

      </Theme>
  );
}


export default App;
