import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Checkbox } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';



const Prizepool = (props) => {
   return (
      <div className='mb-5'>
         <FormGroup>
            <div className='mb-5'>
               <TextField
                  id="first-place"
                  label="First Place Allocation"
                  type="number"
                  placeholder='$'
                  onChange={(newValue) => { props.updateNewChallenge({ first_allocation: newValue.target.value }) }}
               />
            </div>
            <div className='mb-5'>
               <TextField
                  id="second-place"
                  label="Second Place Allocation"
                  type="number"
                  placeholder='$'
                  onChange={(newValue) => { props.updateNewChallenge({ second_allocation: newValue.target.value }) }}
               />
               <FormControlLabel
                  value="1"
                  control={<Checkbox color="primary" />}
                  label="Opt: Second Place Prize"
                  labelPlacement="end"
                  onChange={(newValue) => { props.updateNewChallenge({ opt_second_allocation: newValue.target.value }) }}
               />
               <TextField
                  id="third-place"
                  label="Third Place Allocation"
                  type="number"
                  placeholder='$'
                  onChange={(newValue) => { props.updateNewChallenge({ third_allocation: newValue.target.value }) }}
               />
               <FormControlLabel
                  value="1"
                  control={<Checkbox color="primary" />}
                  label="Opt: Third Place Prize"
                  labelPlacement="end"
                  onChange={(newValue) => { props.updateNewChallenge({ opt_third_allocation: newValue.target.value }) }}
               />
            </div>
         </FormGroup>
      </div>
   )
}

export default Prizepool
