import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Prizepool from './Prizepool';
import Projectinfo from './Projectinfo'
import MultiselectProject from './MultiselectProject'
import Axios from 'axios';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Multiselect from './Multiselect';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Criteria from './Criteria';
import { useEffect } from 'react';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MultiFormControl-root': {
      width: '50%'
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 500,
    maxWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

const convertFileToBase64 = async (file) => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', function () {
      resolve(this.result);
    });
    reader.readAsDataURL(file);
  });
}

const CreateChallenges = (props) => {

  const [newChallenge, setNewChallenge] = useState({});
  const [personName, setPersonName] = React.useState([]);
  const [entity, setEntity] = useState({
    companies: [],
    criteria: [],
    requirements: []
  });
  const [errors, setErrors] = useState([]);

  const handleChange = (event, child) => {
    // console.log('event',event);
    // console.log('child',child);
    setPersonName(event.target.value || "");
    if (event.target.name === 'company') {
      updateNewChallenge({ company_id: child.props.id });
    }
  };

  const updateNewChallenge = (newData) => {
    let newDataObject = {
      ...newChallenge,
      ...newData
    }
    console.log('New Challenge: ', newDataObject);
    setNewChallenge(newDataObject);
  }

  const getCompanies = async () => {
    return await Axios.get('/company')
      .then(res => {
        return res.data.data;
      }).catch((err) => {
        console.log(err);
      });
  }

  const getCriteria = async () => {
    return await Axios.get('/criteria')
      .then(res => {
        return res.data;
      }).catch((err) => {
        console.log(err);
      });
  }

  const getRequirements = async () => {
    return await Axios.get('/requirement')
      .then(res => {
        return res.data;
      }).catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {

    let isMounted = true;

    Promise.all([getCompanies(), getCriteria(), getRequirements()])
    .then((result) => {
      if(isMounted){
        setEntity({
          companies: result[0],
          criteria: result[1],
          requirements: result[2]
        });
      }
    });
    
    return () => {
      isMounted = false;
    }

  }, []);

  const createNewChallenge = (e) => {
    // let data = new FormData();
    // for (const property in newChallenge) {
    //   if (typeof newChallenge[property] === 'object' && !(newChallenge[property] instanceof Date) && !(newChallenge[property] instanceof File))
    //   {
    //     data.append(property, JSON.stringify(newChallenge[property]));
    //     continue;
    //   }
    //   else if((newChallenge[property] instanceof File)){
    //     let [url] = await Promise.all([convertFileToBase64(newChallenge[property])]);
    //     data.append(property, JSON.stringify(url));
    //     continue;
    //   }
    //   data.append(property, newChallenge[property]);
    // }
    let data = JSON.stringify(newChallenge);

    let valid_data = true;
    let errors = {
      name: false,
      company_id: false,
      category: false,
      start_date: false,
      end_date: false,
      prize_money: false
    };
    if (!newChallenge.name) {
      errors.name = true;
      valid_data = false;
    }
    if (!newChallenge.company_id) {
      errors.company_id = true;
      valid_data = false;
    }
    if (!newChallenge.category) {
      errors.category = true;
      valid_data = false;
    }
    if (!newChallenge.start_date) {
      errors.start_date = true;
      valid_data = false;
    }
    if (!newChallenge.end_date) {
      errors.end_date = true;
      valid_data = false;
    }
    if (!newChallenge.prize_money) {
      errors.prize_money = true;
      valid_data = false;
    }

    if (!valid_data) {
        setErrors( errors );
      e.persist();
    }else{
       Axios.post('/challenge', newChallenge)
        .then(res => {
          if (res.status === 200) {
            alert('Challenge Submitted')
            // return window.location.pathname = '/admin-dashboard'
          } else {
            alert('Please check all information is correct')
          }
        }).catch((err) => {
          if(err.response.data) {
            setErrors(err.response.data)
          }
          console.log(err);
        });
    }
  }

  const classes = useStyles();

  return (
    <div className='xl:p-10 sm:p-0 overflow-x-hidden container px-4'>
      <form className={classes.root} noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
        <div className='pt-5'>
          <h1 className='m-auto w-full my-5 border-b border-dash text-makeblue text-2xl'> Challenge Sponsor </h1>
          <div className='flex flex-wrap -mx-4'>
            <div className='w-full md:w-1/2 px-4'>
              <FormControl className={classes.formControl}>
                <InputLabel id="company_selector">Select Company</InputLabel>
                <Select
                  labelId="company_selector"
                  id="company_selector"
                  multiple
                  value={personName}
                  onChange={handleChange}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                  name="company"
                  required
                  error={errors.company_id && !newChallenge.company_id}
                >
                  {entity.companies.map((company) => (
                    <MenuItem key={company.name} value={company.name} id={company.id}>
                      <Checkbox checked={personName.indexOf(company.name) > -1} />
                      <ListItemText primary={company.name} />
                    </MenuItem>
                  ))}
                </Select>
                  {errors.company_id && !newChallenge.company_id &&<div className="w-full text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">Company is required</div>}
              </FormControl>
            </div>
          </div>
        </div>

        <Multiselect handleChange={handleChange} updateNewChallenge={updateNewChallenge} error={errors.category && !newChallenge.category} />
        <div className='pt-5 w-full'>
          <h1 className='m-auto w-full my-5 border-b border-dash text-makeblue text-2xl'> Challenge Information </h1>
          <div className='flex flex-wrap -mx-4'>
            <div className='w-full md:w-1/2 px-4'>
              <TextField
                id="challenge-name"
                label="Challenge Name"
                type="name"
                placeholder=''
                onChange={(newValue) => { updateNewChallenge({ name: newValue.target.value }) }}
                error = {errors.name && !newChallenge.name}
                required
              />
              {errors.name && !newChallenge.name &&<div className="w-5/6 text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">Name is required</div>}
            </div>
            <div className='w-full md:w-1/2 px-4'>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="startDate"
                    label="Start Date"
                    autoOk={true}
                    value={null}
                    inputValue={newChallenge['start_date'] ? moment(newChallenge['start_date']).format('MM/DD/YYYY') : null}
                    onChange={(newValue) => { updateNewChallenge({ start_date: moment(newValue).format('YYYY-MM-DD') }) }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    error={errors.start_date && !newChallenge.start_date}
                  />
                  {errors.start_date && !newChallenge.start_date &&<div className="w-5/6 text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">Start Date is required</div>}
                </>
              </MuiPickersUtilsProvider>
            </div>
          <div className='w-full md:w-1/2 px-4'>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="endDate"
                  label="End Date"
                  autoOk={true}
                  value={null}
                  inputValue={newChallenge['end_date'] ? moment(newChallenge['end_date']).format('MM/DD/YYYY') : null}
                  onChange={(newValue) => { updateNewChallenge({ end_date: moment(newValue).format('YYYY-MM-DD') }) }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  error={errors.end_date && !newChallenge.end_date}
                />
                {errors.end_date && !newChallenge.end_date && <div className="w-5/6 text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">End Date is required</div>}
              </>
            </MuiPickersUtilsProvider>
          </div>
          <div className='w-full md:w-1/2 px-4'>
            <TextField
              id="prize-total"
              label="Prize Pool Total"
              type="number"
              placeholder='$'
              onChange={(newValue) => { updateNewChallenge({ prize_money: newValue.target.value }) }}
              error={errors.prize_money && !newChallenge.prize_money}
              />
            {errors.prize_money && !newChallenge.prize_money &&<div className="w-5/6 text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">Prize Money is required</div>}
          </div>
          </div>
        </div>
        <div className='w-full xl:flex sm:block gap-16'>
          <div className='pt-5 w-5/12'>
            <Prizepool updateNewChallenge={updateNewChallenge} {...props} />
          </div>

          <div className='pt-5 w-1/2'>
            <Projectinfo updateNewChallenge={updateNewChallenge} {...props} />
          </div>
        </div>


        <h1 className='m-auto w-full my-5 border-b border-dash text-makeblue text-2xl'>Criteria</h1>
        <Criteria entity={entity.criteria} onUpdateCriteria={updateNewChallenge}/>
        <div className='pt-5'>
          <MultiselectProject requirements={entity.requirements} errors={errors} handleChange={handleChange} updateNewChallenge={updateNewChallenge} {...props} />
        </div>
        <div className='w-full m-auto text-center text-white'>
          <button className='w-1/5 p-3 mt-5 bg-makeblue rounded submit-hover delay-75' onClick={(e) => createNewChallenge(e)}> Submit </button>
        </div>
      </form>
    </div>
  );
}
export default CreateChallenges