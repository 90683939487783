import React from 'react'
import { Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

function M4Switch(props) {
    const useStyles = makeStyles({
        // checked: {
        //     '& > span': {
        //         color: '#8dc642'
        //     },
        //     '& > span .MuiSwitch-track': {
        //         color: 'red'
        //     }
        // },
    });

    const classes = useStyles();
    
    return <Switch classes={{checked: classes.checked}} {...props}/>;
}

export default M4Switch;