import React from 'react'
import MaterialTable from 'material-table'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios';



const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const ChallengeTab = (props) => {
  const columns = [
    {
      title: "Challenges",
      field: "name",
    },
    {
      title: "Start Date",
      field: "start_date",
    },
    {
      title: "End Date",
      field: "end_date",
    },
    {
      title: "Prize",
      field: "prize_money",
    },
    {
      title: "Categories",
      field: "category",
    },
    {
      title: "Video",
      field: "video_url",
    },
    {
      title: "Material List",
      field: "material_list",
    },
    {
      title: "Description",
      field: "description",
      render: data => truncateText(data.description)
    },
    {
      title: "First Allocation",
      field: "first_allocation",
    },
    {
      title: "Second Allocation",
      field: "second_allocation",
    },
    {
      title: "Third Allocation",
      field: "third_allocation",
    },
  ];

  const truncateText = (inputText) => {
    if(inputText && inputText.length > 100){
      return inputText.slice(0, 97) + "...";
    }
    return inputText;
  }

  const editChallenge = (updatedChallenge) => {
    axios.put('/challenge/' + updatedChallenge.id, updatedChallenge)
      .then(() => {
        props.onUpdateChallenge(updatedChallenge);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  

  const deleteChallenge = (challengeToDelete) => {
    axios.delete('/challenge/' + challengeToDelete.id)
      .then(res => {
        props.onDeleteChallenge(challengeToDelete);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className='m-auto mt-24'>
      
      <MaterialTable
        icons={tableIcons}
        title="Challenges"
        columns={columns}
        data={props.challenges}
        detailPanel={ (currentRowData) => {
          console.log('rowdata:', currentRowData)
            return (
              <>
                <h1 className='m-auto w-full my-5 border-b p-2 border-dash text-makeblue text-2xl'>Participants</h1>
                {currentRowData.participants.length > 0 && currentRowData.participants.map((participant, index) => (
                  <ul className='flex relative p-5 participant-row'>
                    <li className='p-2 w-1/12' key={participant.pivot.user_id}>{(participant.last_name).toUpperCase()}, {(participant.first_name).toUpperCase()}</li>
                    <li className='p-2 w-1/12' key={participant.pivot.user_id}>{(participant.email).toUpperCase()}</li>
                  </ul>
                ))}
                {currentRowData.participants.length === 0 && (
                  <ul className='flex relative p-5 participant-row'>
                    <li className='p-2 w-1/12'>No Participants Yet</li>
                  </ul>
                )}
              </>
          )
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                if (oldData) {
                  editChallenge(newData);
                }
              }, 600);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                deleteChallenge(oldData);
              }, 600);
            }),
        }}
      />
    </div>
  );
}

export default ChallengeTab


