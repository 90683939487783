import { makeStyles, Paper } from '@material-ui/core';
import React from 'react'
import IntoLogo from '../Assets/video.png'

function Challenge() {
    const useStyles = makeStyles({
        Paper: {
            'background-color': 'rgb(248 250 252 / var(--tw-bg-opacity))'
        }
    })();

    return ( 
        <div className="relative container m-auto px-4">
            <div className='text-center text-2xl pt-12 w-full m-auto font-hairline'>
                <div className='xl:w-full lg:w-auto m-auto'>
                    <h1 className='w-full text-7xl font-bold font-display mb-5'>
                        Challenge Accepted!
                    </h1>
                    <div className='container m-auto px-4'>
                        <div className='flex my-10'>
                            <Paper elevation={2} className={`${useStyles.Paper} p-3 bg-slate-50 grid grid-cols-2`}>
                                <div className='m-auto'>
                                    <img src={IntoLogo} alt="" height='570px'/>
                                </div>
                                <div className='text-xl m-auto'>It's all about building new innovation in the form of either a Digital or Physical prototype.  Just like our Make48 TV documentary. We are now bringing the opportunity to you at your home, college or city MAKERSPACE. Our leading innovation partners need innovation and are asking if YOU have the best idea. You have 48 days to Solve it.</div>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Challenge;