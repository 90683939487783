import React, { useState, useEffect } from 'react'
import Hero from '../ChallengePage/Comphero'
import Details from '../ChallengePage/Details'
import Zoom from '../ChallengePage/Zoom'
import Collboration from '../Home/Collaboration'
import More from '../ChallengePage/Moresignup'
import Accordbutton from '../ChallengePage/Accordbutton';
import Signup from '../LoggedOut/Challengeloggedout'
import axios from 'axios';
import NewsletterSignUp from '../LoggedIn/NewsletterSignUp'

const Competition = (props) => {
    const [state, setState] = useState({
        company: null,
        challenge: null
    })

    async function getChallenge(challenge_id) {
        return await axios.get('/challenge/' + challenge_id)
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        let challenge_id = props.match.params.id;
        Promise.all([getChallenge(challenge_id)])
            .then((res) => {
                setState({
                    company: res[0].data.data.company,
                    challenge: res[0].data.data
                });
            })
    }, [])

    let newsletter_signup = localStorage.getItem('newsletter_signup');

    if(!state.company && !state.challenge){
        return null;
    }

    if (!props.user && newsletter_signup !== 'true') {
        return (
            <Signup user={props.user} createNewUser={props.createNewUser} updateNewUser={props.updateNewUser} company={state.company} {...props} />
        )
    } else if (!props.user && newsletter_signup === 'true'){
        return (
            <>
                <Hero challenge={state.challenge} company={state.company} />
                <Details challenge={state.challenge} />
                <Zoom challenge={state.challenge} company={state.company} companies={props.companies} />
                <div className='text-center m-auto justify-center'>
                    <Accordbutton />
                </div>
                <Collboration />
                <NewsletterSignUp user={props.user} createNewUser={props.createNewUser} updateNewUser={props.updateNewUser} {...props}/>
            </>
        )
    } else  {
        return (
            <>
                <Hero challenge={state.challenge} company={state.company} companies={props.companies} />
                <Details challenge={state.challenge} />
                <Zoom challenge={state.challenge} company={state.company} companies={props.companies} />
                <div className='text-center m-auto justify-center'>
                    <Accordbutton />
                </div>
                <More {...props} />
            </>
        )
    }
}


export default Competition