import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { RiArrowUpSLine } from 'react-icons/ri';
import Leaders from './Leaderboard'
import Follower from './Followup'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    width: '100%'
  },

}));


const Acorrdbutton = (props) => {
  const [winners, setWinners] = useState();
  const classes = useStyles();

  if (winners) {
    return (
      <div className='w-4/5 m-auto'>
        <Accordion styles={{
          backgroundColor: 'blue',
        }}

        >
          <AccordionSummary
          >
            <Typography component={'span'} className={classes.heading}>
              <div className='w-full text-center justify-center m-auto'>
                <button id='accordianButton' className='text-3xl font-medium p-2 border rounded-full bg-white shadow focus:outline-none focus:shadow-outline rotate-180 inline-block'><RiArrowUpSLine /></button>
              </div>


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component={'span'}>
              <div className='w-full'>

                <Leaders />

              </div>

              <div className='w-full'>

                <Follower />

              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  } else {
    return null
  }
}

export default Acorrdbutton