import React, {useState} from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ChallengeTab from './Challengetab'
import CreateChallenges from '../CreationForms/CreateChallenges'
import AdminCompanies from './Admincompanies'
import { Redirect } from "react-router-dom";
import axios from 'axios';
import CreateCompanies from '../CreationForms/CreateCompanies'
import { useEffect } from 'react';
import ChallengeDefaults from './ChallengeDefaults';


const Admindash = (props) => {

    let statsDefault = {
        total_challenges: 0,
        total_past_challenges: 0,
        total_current_participants: 0,
        total_past_participants: 0,
        total_current_companies: 0,
        total_past_companies: 0,
    }

    const [state, setState] = useState({
        challenges: [],
        companies: [],
        statsLoaded: false,
        stats: statsDefault,
        defaultIndex: 0
    });

    async function getStats () {
        return await axios.get('/stats')
            .catch((err) => {
                console.log(err);
            });
    }

    async function getChallenges () {
        try {
            return await axios.get('/challenge');
        } catch (err) {
            console.log(err);
        }
    }

    async function getCompanies () {
        return await axios.get('/company')
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        let isMounted = true;
        Promise.all([getStats(), getChallenges(), getCompanies()])
            .then((res) => {
                const stats = res[0].data.data;
                const challenges = res[1].data.data;
                const companies = res[2].data.data;

                if(isMounted){
                    setState({
                        challenges,
                        stats,
                        companies
                    });
                }
            });

        return () => { isMounted = false };
    }, [])

    const handleUpdateChallenge = (challenge) => {
        setState((currState) => {
            let newChallenges = currState.challenges.map((c) => {
                if(c.id === challenge.id){
                    return challenge;
                }
                return c;
            });
            return {
                ...currState,
                challenges: newChallenges
            }
        });
    }

    const handleDeleteChallenge = (challenge) => {
        setState((currState) => {
            let newChallenges = currState.challenges.filter((c) => c.id !== challenge.id);
            return {
                ...currState,
                challenges: newChallenges
            }
        });
    }

    const handleAddCompany = (company) => {
        setState((currState) => {
            let newCompanies = currState.companies.concat(company);
            return {
                ...currState,
                companies: newCompanies
            }
        });
    }

    const handleUpdateCompany = (company) => {
        setState((currState) => {
            let newCompanies = currState.companies.map((c) => {
                if(c.id === company.id){
                    return company;
                }
                return c;
            });
            return {
                ...currState,
                companies: newCompanies
            };
        });
    }

    const handleDeleteCompany = (company) => {
        setState((currState) => {
            let newCompanies = currState.companies.filter((c) => c.id !== company.id);
            return {
                ...currState,
                companies: newCompanies
            }
        });
    }
    
    if (props.user && props.user.role_id === 1) {

        return (

            <div className='w-4/5 h-full m-auto mt-3'>
                <Tabs defaultIndex={state.defaultIndex}>
                    <TabList>
                        <div className='xl:flex sm:block'>
                            <Tab><button className='text-3xl px-3 border-makeblue border-r-2 rounded-lg border-opacity-75 shadow border-dashed mx-2 focus:outline-none focus:shadow-outline focus:bg-makeblue'>Admin</button></Tab>
                            <Tab><button className='text-3xl px-3 border-makeblue border-r-2 rounded-lg border-opacity-75 shadow border-dashed mx-2 focus:outline-none focus:shadow-outline focus:bg-makeblue'>Challenges</button></Tab>
                            <Tab><button className='text-3xl px-3 border-makeblue border-r-2 rounded-lg border-opacity-75 shadow border-dashed mx-2 focus:outline-none focus:shadow-outline focus:bg-makeblue'>Create Challenges</button></Tab>
                            <Tab><button className='text-3xl px-3 border-makeblue border-r-2 rounded-lg border-opacity-75 shadow border-dashed mx-2 focus:outline-none focus:shadow-outline focus:bg-makeblue'>Challenge Defaults</button></Tab>                            
                            <Tab><button className='text-3xl px-3 border-makeblue border-r-2 rounded-lg border-opacity-75 shadow border-dashed mx-2 focus:outline-none focus:shadow-outline focus:bg-makeblue'>Create Company</button></Tab>
                            <Tab><button className='text-3xl px-3 border-makeblue border-r-2 rounded-lg border-opacity-75 shadow border-dashed mx-2 focus:outline-none focus:shadow-outline focus:bg-makeblue'>Companies</button></Tab>
                        </div>
                    </TabList>
                    <TabPanel>
                        <div className='xl:flex sm:block xl:px-20 xl:pt-20 sm:pt-0 sm:px-0'>
                            <div className='w-full bg-makeblue text-center text-2xl my-2 mx-2 py-16 border-solid border shadow rounded-lg m-auto'>
                                <div id='background' className='bg-center bg-cover bg-no-repeat h-64 w-full rounded-t-lg'>
                                    <h1 className='text-white p-3'>Total Challenges</h1>
                                    <span className='text-white pt-10 text-5xl'>{state.stats.total_challenges}</span>
                                </div>
                            </div>
                            <div className='w-full bg-makeblue text-center text-2xl my-2 mx-2 py-16 border-solid border shadow rounded-lg m-auto'>
                                <div id='background' className='bg-center bg-cover bg-no-repeat h-64 w-full rounded-t-lg'>
                                    <h1 className='text-white p-3'>Total Current Participants</h1>
                                    <span className='text-white pt-10 text-5xl'>{state.stats.total_current_participants}</span>
                                </div>
                            </div>
                            <div className='w-full bg-makeblue text-center text-2xl my-2 mx-2 py-16 border-solid border shadow rounded-lg m-auto'>
                                <div id='background' className='bg-center bg-cover bg-no-repeat h-64 w-full rounded-t-lg'>
                                    <h1 className='text-white p-3'>Total Companies</h1>
                                    <span className='text-white pt-10 text-5xl'>{state.stats.total_current_companies}</span>
                                </div>
                            </div>
                        </div>

                        <div className='xl:flex sm:block xl:px-20 sm:p-0'>
                            <div className='w-full bg-makeblue text-center text-2xl my-2 mx-2 py-16 border-solid border shadow rounded-lg m-auto'>
                                <div id='background' className='bg-center bg-cover bg-no-repeat h-64 w-full rounded-t-lg'>
                                    <h1 className='text-white p-3'>Total Past Participants</h1>
                                    <span className='text-white pt-10 text-5xl'>{state.stats.total_past_participants}</span>
                                </div>
                            </div>
                            <div className='w-full bg-makeblue text-center text-2xl my-2 mx-2 py-16 border-solid border shadow rounded-lg m-auto'>
                                <div id='background' className='bg-center bg-cover bg-no-repeat h-64 w-full rounded-t-lg'>
                                    <h1 className='text-white p-3'>Past Challenges</h1>
                                    <span className='text-white pt-10 text-5xl'>{state.stats.total_past_challenges}</span>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <ChallengeTab 
                        challenges={state.challenges}
                        onUpdateChallenge={handleUpdateChallenge} 
                        onDeleteChallenge={handleDeleteChallenge} 
                        user={props.user} />
                    </TabPanel>
                    <TabPanel>
                        <CreateChallenges />
                    </TabPanel>
                    <TabPanel>
                        <ChallengeDefaults />
                    </TabPanel>
                    <TabPanel>
                        <CreateCompanies />
                    </TabPanel>
                    <TabPanel>
                        <AdminCompanies 
                            companies={state.companies} 
                            onAddCompany={handleAddCompany}
                            onUpdateCompany={handleUpdateCompany}
                            onDeleteCompany={handleDeleteCompany} />
                    </TabPanel>

                </Tabs>
            </div>
        )
    } else {
        return (

            <Redirect to='/admin-dashboard' />
        )
    }
}

export default Admindash
