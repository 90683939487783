import React from 'react'
import TextField from '@material-ui/core/TextField';


const Projectinfo = (props) => {
    return (
        <React.Fragment>
            <label>
                <TextField
                    id="video-url"
                    label="Video URL"
                    type="URL"
                    placeholder=''
                    onChange={(newValue) => { props.updateNewChallenge({ video_url: newValue.target.value }) }}
                />
            </label>
        </React.Fragment>
    )
}

export default Projectinfo
