import React, { useContext, useEffect, useState} from 'react'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import axios from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, TextField, makeStyles } from '@material-ui/core';
import DashboardCard from './DashboardCard';
import M4Loader from '../../Globals/CommonComponents/M4Loader';
import { UserContext } from '../../Globals/Hooks/UserContext';
import { AlertContext } from '../../Globals/Hooks/AlertContext';
import M4FileUploader from '../../Globals/CommonComponents/M4FileUploader';
import Axios from 'axios';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles({
  fileItem: {
    margin: "auto",
    marginBottom: "12px"
  },
  textItem: {
    marginBottom: "12px"
  },
  divider: {
    marginBottom: "12px"
  }
});

const Userdash = (props) => {

  const [agree, setAgree] = useState(false);
  const [challenges, setChallenges] = useState();
  const [submit, setSubmit] = useState();
  const [showResults, setShowResults] = useState(false);
  const [open, setToOpen] = useState([]);
  const [activeChallenge, setActiveChallenge] = useState({});
  const [responses, setResponses] = useState({});
 
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const { setAlert } = useContext(AlertContext);

  const loadDashboard = () => {

  };

  // const deleteChallenge = (challengeToDelete) => {
  //   console.log('test', challengeToDelete.pivot.user_id)
  //   axios.post('/challenge/' + challengeToDelete.id + '/user/' + challengeToDelete.pivot.user_id +'/unapply')
  //     .then(res => {
  //        updateChallenge()
  //     }).catch((err) => {
  //       console.log(err);
  //     });
  // }
  

  const getChallenges = async () => {
    return await axios.get('/challenge/user/' + user.id)
      .then(res => {
        return res.data;
      }).catch((err) => {
        console.log(err);
      });
  }

  // const submitChallenge = () => {
  //   let data = {
  //     user_id: user ? user.id : 0,
  //     challenge_id: activeChallenge.id
  //   }
    
  //   axios.post('/challenge/submission', data)
  //     .then(res => {
  //       setSubmit(res.data);
  //       alert('Thank you for participating, your file as been uploaded!')
  //     }).catch((err) => {
  //       console.log(err);
  //     });
  // }

  const onFileUpload = (id, file) => {
    let newValues = responses;
    newValues[id] = file;
    setResponses(newValues);
  };

  const onTextChange = (id, newValue) => {
    let newValues = responses;
    newValues[id] = newValue;
    setResponses(newValues);
    console.log(responses);
  };

  const getSubmitFields = (challenge) => {
    let formFields = [];

    challenge.requirements.forEach((r, index) => {
      if(r.type === "file")
      {
        formFields.push(
          <M4FileUploader key={r.title + "--" + index} index={index} className={classes.fileItem} label={r.title} onFileUpload={file => onFileUpload(r.id, file)}/>
        );
      }
      else if (r.type === "textSingle") 
      {
        formFields.push(
          <TextField key={r.title + "--" + index} className={classes.textItem} label={r.title} onChange={e => onTextChange(r.id, e.target.value)}/>
        );
      }
      else if (r.type === "textMulti")
      {
        formFields.push(
          <TextField key={r.title + "--" + index} className={classes.textItem} label={r.title} multiline maxRows={6} onChange={e => onTextChange(r.id, e.target.value)}/>
        );
      }

      formFields.push(
        <Divider className={classes.divider} />
      );
    });

    return formFields;
  }

  useEffect(() => {
    Promise.all([getChallenges()])
      .then((res) => {
        setChallenges(res[0]);
      }).catch((err) => {
        console.log(err);
      });
  }, [user]);

  const handleOpenSubmit = (id) => {
    setToOpen(open.concat([id]));
  }

  const handleSubmit = (challengeId) => {
    let values = {};
    values.user_id = user.id;
    values.challenge_id = challengeId;
    values.responses = responses;
    Axios.post('/challenge/submission', values)
      .then(res => {
        setAlert('Thank you for participating, your submission as been uploaded!', 'success');
        setToOpen(open.filter(id => id != challengeId));
        const link = document.createElement("a");
        link.href = responses["1"].data;
        link.download = responses["1"].fileName;
        link.click();
      }).catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className='container px-4 m-auto'>
        <h1 className='w-full text-7xl font-bold font-display mb-5 m-auto'>
            My Challenges
        </h1>
        <Divider />
        {/* {user_challenges !== undefined && 
          // <M4Loader />
        } */}
        <div className='container flex'>
          {challenges && challenges.map((c, index) => 
            <div key={c.name + "-" + index}>
              <DashboardCard challenge={c} onOpenSubmit={() => handleOpenSubmit(c.id)} />
              <Dialog key={c.id} open={open.includes(c.id)} onClose={() => setToOpen(open.filter(id => id != c.id))}>
                <DialogTitle>Submit Challenge {c.name}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Submit the required challenge documentation below
                  </DialogContentText>
                  <div className='flex flex-col'>
                    {getSubmitFields(c)}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setToOpen(open.filter(id => id != c.id))} color='primary'>
                    Cancel
                  </Button>
                  <Button onClick={() => handleSubmit(c.id)} color='primary'>
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}
        </div>
      </div>
    </>
  )

}

export default Userdash
