import React from 'react'
import moment from 'moment';

function Hero(props) {
    let challenge = props.challenge;
    if (!challenge.category) {
        challenge = { category: '' }
    }
    function getRemainingDays(endDate) {
        var momentUtcDate = moment().utc();
        var momentEndDate = moment(endDate);
        var daysLeft = momentEndDate.diff(momentUtcDate, "days");
        if(daysLeft > 48){
            return "Coming Soon";
        }
        else if(daysLeft < 0){
            return "Completed";
        }else{
            return daysLeft;
        }
    }

    let image_url = process.env.REACT_APP_API_URL.replace('/api', '');

    function getVideoUrl(url) {
        if(url.includes("embed")){
            return url + "?controls=0";
        }
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
            return "https://www.youtube.com/embed/" + match[2] + "?controls=0";
        } else {
            //error
        }
    }

    const remainingDays = props.challenge ? getRemainingDays(props.challenge.end_date) : 0;
    console.log(props.challenge.video_url);
    return (
        <>
            {!props.challenge.name && 
                <div className="text-center"> 
                        <div className='loadding-icon'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            }
            <div id='heroBg' className='text-white text-center bg-center bg-contain bg-no-repeat bg-black h-auto block py-5' style={{ backgroundImage: `url(${props.challenge ? (image_url + props.challenge.image_url) : ''})`, minHeight: "400px" }}>
                <div id='heroOverlay'></div>
                <div className='w-3/5 m-auto mb-4'>
                    <span> <img id='icons' className='object-cover m-auto -mt-8 border-black rounded-full' src={image_url + props.company.logo_url} alt='jdIcon'></img></span>
                    <h1 id='challengeTitle' className='text-5xl xl:w-3/5 sm:w-full m-auto font-thin'> {props.challenge ? props.challenge.name : ''}</h1>
                    <div>
                        {props.challenge.video_url && 
                            <iframe src={getVideoUrl(props.challenge.video_url)} height='500px' width='800px' className='mt-8 mx-auto'></iframe>
                        }
                    </div>
                </div>
            </div>
            <div className='w-full m-auto bg-makeblue p-4'>
                <div className='flex xl:w-2/5 md:w-full m-auto text-center text-white'>
                    <div className=' w-2/5 m-auto'>
                        <span id='prizePool' className='text-4xl font-thin block'>${props.challenge ? props.challenge.prize_money : ''}</span>
                        <span className='text-lg font-hairline'>Prize Pool</span>
                    </div>
                    <div className=' w-2/5 m-auto'>
                        <span id='daysLeft' className='text-4xl font-thin block'>{props.challenge ? remainingDays : ''}</span>
                        {remainingDays < 48 && remainingDays > 0 && 
                            <span className='text-lg font-hairline'>Days Left</span>
                        }
                    </div>
                </div>
            </div>
            <div className='border-b shadow-sm w-full m-auto'>
                <div className='flex xl:w-4/5 md:w-full m-auto justify-center p-5 text-makeblue'>
                    <span className='xl:px-10 md:px-2'>CATEGORY:</span>
                    {
                        challenge.category.split(',').map((category, index) => {
                            return (<span key={index} id='cat{index}' className='px-10'>{category.toUpperCase()}</span>);
                        })
                    }

                </div>
            </div>
        </>

    )

}

export default Hero