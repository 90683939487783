import { Card, CardContent, CardHeader, Checkbox, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    card: {
        height: 'fit-content',
        margin: '15px 15px 15px 15px'
    },
    header: {
        backgroundColor: theme.palette.grey[100]
    }
})); 

function Criteria(props) {
    const classes = useStyles();
    const getCriteriaList = (itemDescription) => {
        return (
            <ListItem>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        color='Primary'
                    />
                </ListItemIcon>
                <ListItemText 
                    id={itemDescription}
                    primary={itemDescription}
                />
            </ListItem>
        );
    }

    if(props.headers === null)
    {
        return null;
    }

    return (
        <>
            <div className='flex flex-col'>
                {props.headers.map((header) => 
                    <>
                        {header.criteria && header.criteria.length > 0 &&
                            <Card className={classes.card}>
                                <CardHeader
                                    className={classes.header}
                                    title={header.header}
                                />
                                <CardContent>
                                    <List>
                                        {header.criteria.map((c) => 
                                            getCriteriaList(c.criteria)
                                        )}
                                    </List>
                                </CardContent>
                            </Card>
                        }
                    </>
                )}
            </div>
        </>
    )
}

export default Criteria;