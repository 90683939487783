import React from 'react'
import Firstplace from './Firstplace'
import Secondplace from './Secondplace'
import Thirdplace from './Thirdplace';

function Leaders(){
    return (

        <div className='w-full m-auto'>
            <div className='leaderContainer w-full m-auto text-center'>
                <h1 className='text-4xl font-thin'>Design Winners</h1>
                    <div className='border-b w-1/4 m-auto shadow-sm'></div>
                <div className='w-full xl:flex sm:block m-auto justify-center'>
                    <div className='mx-5'>
                        <Secondplace/>
                    </div>

                    <div className='mx-5'>
                        <Firstplace/>
                    </div>

                    <div className='mx-5'>
                        <Thirdplace/>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default Leaders