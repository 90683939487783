import React from 'react'
import video from '../Assets/video.png'

function Works() {
    return (
        <div className='bg-works'>
            <div className="container m-auto px-4">
                <div className='pb-20 md:flex md-flex-wrap md:-mx-3'>
                    <div className='xl:w-1/2 sm:w-full md:mx-3 mb-5 how-work'>
                        <h1 className='text-5xl font-thin pt-20 xl:text-left sm:text-center mb-4'>How does it work?</h1>
                        <span className='text-xl font-hairline pt-3 pr-5 xl:pb-40 xl:text-left sm:pb-20 sm:text-center'>A company needs to solve a problem.  They want either a CAD drawing, a full "works like, feels like" prototype or something in between. In our 48 day Challenge, the Company sets parameters and will be available for weekly Q&A sessions. YOU will have 48 Days to solve the Challenge. Build or design from your home, a community makerspace or even at your college. You can do this by yourself or work with a team that have different skill sets... A cash prize is awarded for 1st ,2nd and 3rd place in exchange for the IP.  At Make48 we strongly believe everyday people could have the best idea. </span>
                    </div>
                    <div className='xl:w-1/2 sm:w-full text-center sm:pb-10 md:mx-3'>
                        <div className='bg-gray-900 rounded-lg xl:mx-24 sm:mx-auto'>
                            <img src={video} alt='video' className='opacity-25 m-auto xl:mt-32 sm:mt-auto rounded-lg'></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Works;