import React from 'react'
import { Link } from 'react-router-dom';
import AnimatedModal from './Animatedmodal';
import M4Avatar from './CommonComponents/M4Avatar';


const Navigation = (props) => {

    let userData = props.user;

    // console.log(!props.user === '1')
    // console.log(!props.user === 1)
    if (props.user != null && props.user.role_id === 1) {
        // console.log('returns:', props.user)j
        function logout() {
            localStorage.clear();
            window.location.href = '/';
        }

        return (
            <React.Fragment>
                <ul className="flex xl:pr-10 sm:mr-5 xl:mr-0 items-center">
                    <li className='p-5'>
                        <Link to="/" className='text-white text-thin'>Home</Link>
                    </li>
                    <li className='p-5'>
                        <Link to="/challenges" className='text-white text-thin'>Challenges</Link>
                    </li>
                    <li className='p-5'>
                        <Link to="/admin-dashboard" className='text-white text-thin border-white border-opacity-50 border-2 p-2 px-8 rounded-lg'>Admin</Link>
                    </li>
                    <li className='p-5'>
                        <Link to={`/bio/${userData.id}`}>
                            <M4Avatar user={userData} color="#AF1B3F"></M4Avatar>
                        </Link>
                    </li>
                    <button className='text-white text-thin border-white border-opacity-50' onClick={logout}>Sign Out</button>
                </ul>
            </React.Fragment>
        );
    } if (props.user != null && props.user.role_id === 2) {
        function logout() {
            localStorage.clear();
            window.location.href = '/';
        }
        return (
            <React.Fragment>
                <ul className="flex xl:pr-10 sm:mr-5 xl:mr-0 items-center">
                    <li className='p-5'>
                        <Link to="/" className='text-white text-thin'>Home</Link>
                    </li>
                    <li className='p-5'>
                        <Link to="/challenges" className='text-white text-thin'>Challenges</Link>
                    </li>
                    <li className='p-5'>
                        <Link to="/user" className='text-white text-thin border-white border-opacity-50 border-2 p-2 px-8 rounded-lg'>Dashboard</Link>
                    </li>
                    <li className='p-5'>
                        <Link to={`/bio/${userData.id}`}>
                            <M4Avatar user={userData} color="#AF1B3F"></M4Avatar>
                        </Link>
                    </li>
                    <button className='text-white text-thin border-white border-opacity-50' onClick={logout}>Sign Out</button>
                </ul>
            </React.Fragment>

        )
    } else {
        return (
            <ul className="flex xl:pr-10 sm:mr-5 xl:mr-0">
                <li className='p-5'>
                    <Link to="/" className='text-white text-thin'>Home</Link>
                </li>
                <li className='p-5'>
                    <Link to="/challenges" className='text-white text-thin'>Challenges</Link>
                </li>
                <li className='p-5'>
                    <AnimatedModal createNewUser={props.createNewUser} updateNewUser={props.updateNewUser} setUserFromLogin={props.setUserFromLogin} authTest={props.authTest} {...props} />
                </li>
            </ul>
        )
    }
}

export default Navigation;
