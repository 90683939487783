import { Divider, Fab, makeStyles, Paper, TextField } from '@material-ui/core';
import {  Edit, FlipCameraIos, Save } from '@material-ui/icons';
import Axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import M4Avatar from '../Globals/CommonComponents/M4Avatar';
import M4Loader from '../Globals/CommonComponents/M4Loader';

const useStyles = makeStyles(theme => ({
    editButton: {
        borderRadius: "999px",
        padding: "0px",
        position: "absolute",
    },
    editIcon: {
        backgroundColor: theme.palette.success.main
    },
    banner: {
        width: "100%",
        height: "12rem",
        position: "relative",
        backgroundColor: theme.palette.secondary.main
    },
    overlay: {
        position: "absolute",
        width: "100%",
        height: "12rem",
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.2)"
        }
    },
    overlayIcon: {
        position: "absolute",
        top: "45%",
        left: "48%"
    },
    verticalDivider: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    listDivider: {
        height: "1px"
    },
    bioTextField: {
        margin: "12px",
        width: "95%"
    }
}));

const BioPage = (props) => {
    const classes = useStyles();

    const [isBannerHover, setIsBannerHover] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isUserLoaded, setUserLoaded] = useState(false);
    
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [bio, setBio] = useState("");

    const [hasUpdatedInfo, setHasUpdatedInfo] = useState(false);

    const handleLoadUser = async (id) => {
        return await Axios.get("/user/" + id)
            .catch((err) => {
                console.log(err);
            });
    }

    const handleSaveUser = () => {
        Axios.put("/user/" + props.match.params.id, {
            first_name: userFirstName ?? "",
            last_name: userLastName ?? "",
            city: city,
            state: state,
            bio: bio
        });
        setHasUpdatedInfo(false);
    }

    const handleEdit = () => {
        if(isEditing){
            handleSaveUser();
        }
        setIsEditing(!isEditing);
    }

    useEffect(() => {
        Promise.all([handleLoadUser(props.match.params.id)])
            .then((res) => {
                const user = res[0].data.data;

                setUserFirstName(user.first_name);
                setUserLastName(user.last_name);
                setCity(user.city);
                setState(user.state);
                setBio(user.bio);

                setUserLoaded(true);
            });
    }, []);

    useEffect(() => {
        setHasUpdatedInfo(true);
    }, [userFirstName, userLastName, city, state, bio]);

    if(!isUserLoaded || !props.user){
        return (
            <div className='container mx-auto flex h-[500px]'>
                <M4Loader className='m-auto'></M4Loader>
            </div>
        );
    }

    const isUser = props.user.id.toString() === props.match.params.id;

    const getLocation = (city, state) => {
        if(city && state){
            return `${city}, ${state}`;
        }
        else if(city && !state){
            return city;
        }
        else if(!city && state) {
            return state;
        }
        else {
            return "Not available";
        }
    }

    return (
        <>
            <div className={classes.banner}>
                {isUser && 
                    <div 
                        onMouseEnter={() => setIsBannerHover(true)} 
                        onMouseLeave={() => setIsBannerHover(false)} 
                        className={classes.overlay}
                    >
                        {isBannerHover && 
                            <FlipCameraIos size='large' className={classes.overlayIcon}/>
                        }
                    </div>
                }
                <M4Avatar user={props.user} size='large' className='absolute top-24 left-16' />
                {isUser && 
                    <Fab 
                        size='large'
                        color='primary' 
                        className={`absolute top-4 right-8 ${classes.editButton}`}
                        onClick={() => handleEdit()}
                    >
                        {!isEditing &&
                            <Edit />
                        }
                        {isEditing && 
                            <Save />
                        }
                    </Fab>
                }
            </div>
            <div className='container mx-auto flex'>
                <div className='w-1/4 h-[25rem] pt-20 container mx-auto flex flex-col items-center'>
                    <div className='font-sans font-bold text-xl'>Name</div>
                    <Divider className={classes.listDivider} variant="middle" flexItem/>
                    {!isEditing && 
                        <div className='font-sans mb-4'>{userFirstName} {userLastName}</div>
                    }
                    {isEditing && 
                        <div className='flex'>
                            <TextField 
                                className={classes.bioTextField}
                                defaultValue = {userFirstName} 
                                onChange = {(e) => setUserFirstName(e.target.value)}
                                label="First Name"
                            />
                            <TextField 
                                className={classes.bioTextField}
                                defaultValue = {userLastName}
                                onChange = {(e) => setUserLastName(e.target.value)}
                                label = "Last Name"
                            />
                        </div>
                    }
                    <div className='font-sans font-bold text-xl'>Location</div>
                    <Divider className={classes.listDivider} variant="middle" flexItem/>
                    {!isEditing &&
                        <div className='font-sans mb-4'>{getLocation(city, state)}</div>
                    }
                    {isEditing && 
                        <div className='flex'>
                            <TextField
                                className={classes.bioTextField}
                                defaultValue={city}
                                onChange={(e) => setCity(e.target.value)}
                                label="City"
                            />
                            <TextField
                                className={classes.bioTextField}
                                defaultValue={state}
                                onChange={(e) => setState(e.target.value)}
                                label="State"
                            />
                        </div>
                    }
                    <div className='font-sans font-bold text-xl'>Interests</div>
                    <Divider className={classes.listDivider} variant="middle" flexItem/>
                </div>
                <Divider orientation='vertical' flexItem className={classes.verticalDivider}/>
                <div className=' flex-1 h-[25rem]'>
                    <Paper elevation={2} className='m-6 p-3 min-h-[8rem]'>
                        <div className='text-2xl font-bold'>Bio</div>
                        <Divider />
                        {!isEditing &&
                            <div className='text-lg mt-2'>{bio}</div>
                        }
                        {isEditing && 
                            <TextField
                                className={classes.bioTextField}
                                defaultValue={bio}
                                onChange = {(e) => setBio(e.target.value)}
                                label = "Bio"
                                multiline
                                inputProps={{maxLength: 500}}
                            />
                        }
                    </Paper>
                </div>
            </div>
        </>
    );
};

export default BioPage;