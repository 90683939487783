import React from 'react'

function Allocation(props) {
    if (!props.challenge.first_allocation) {
        return null
    } else {
        return (
            <React.Fragment>
                <h1 className='text-2xl mt-10'>Prize Pool</h1>
                <p className='text-xl text-gray-500'>Prize Pool will be awarded as follows:</p>
                <ul className='text-xl text-gray-500'>
                    <li>1. ${props.challenge.first_allocation}</li>
                    <li>2. ${props.challenge.second_allocation}</li>
                    <li>3. ${props.challenge.third_allocation}</li>
                </ul>
            </React.Fragment>
        )
    }
}

export default Allocation