import React from 'react'
import axios from 'axios'
import * as yup from 'yup';
import {  useFormik } from "formik";




const Newslettermodal = (props) => {

let validationSchema = yup.object().shape({
  email: yup.string().email("Email Must be Valid ").required("Email is required"),
});


const Register = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      console.log(values);
        axios.post('/newsletter', values)
        .then(res => {
            localStorage.setItem('newsletter_signup', true)
            window.location.reload()
        }).catch((err) => {
            console.log(err);
        });
        
    },
    validationSchema,
  
  });
    // const NewsletterRegister = (e) => {
    //     e.preventDefault();
    //     let data = {
    //         email: emailAddress,
    //         name: name,
    //     }
    //     axios.post('/newsletter', data)
    //     .then(res => {
    //         // console.log(res)
    //             localStorage.setItem('newsletter_signup', true)
    //             setToNewsletter(true)
    //             window.location.reload()
    //         }).catch((err) => {
    //             console.log(err);
    //         });
    // }

    // if (!newsletter) {
    //     localStorage.setItem('newsletter_deny', true)
    // } 

    return (

        <div className='bg-makeblue text-white'>
            <div className='w-full m-auto xl:flex sm:block'>
                <div className='w-full'>
                    <h1 className='p-5 text-5xl text-center font-thin'>Receive our Newsletter.</h1>

                    <form className='justify-center w-full' onSubmit={Register.handleSubmit}>
                    <div className='my-5'>
                        <input className='w-4/5 m-auto rounded-lg bg-makebluetwo block p-3' type='text' id='name' placeholder='Name' onChange={Register.handleChange} onBlur={Register.handleBlur} value={Register.values.name}></input>
                    </div>
                    <div className='my-5'>
                        <input className='w-4/5 m-auto rounded-lg bg-makebluetwo block p-3' type='email' id='email' placeholder='Email'  onChange={Register.handleChange} onBlur={Register.handleBlur} value={Register.values.email}></input>
                        {Register.touched.email && Register.errors.email ? (
                            <div className='w-4/5 m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3'>{Register.errors.email}</div>
                          ) : null}
                    </div>
                        <div className='w-4/5 m-auto text-center'>
                        <button className='p-4 mb-2 bg-button-green text-base shadow rounded-lg' type="submit">Go</button>
                    </div>
                    </form>
                    
                </div>
            </div>
        </div>
    )
}

export default Newslettermodal
