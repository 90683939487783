import React from 'react'
import followOne from '../Assets/Userpic-3.png'
import followTwo from '../Assets/Userpic-4.png'
import followThree from '../Assets/Userpic-5.png'
import followFour from '../Assets/Userpic-6.png'
import followFive from '../Assets/Userpic-7.png'


function Follow() {
    return (
        <div className='w-full pb-32'>
            <div className='pt-20 m-auto flex text-center'>
                <div className='border p-4 bg-makeblue text-xl font-medium text-white m-auto followList'>4
                </div>

                <div className='fullListNames ml-2 flex bg-makeblue'>
                    <img className='rounded-full border-4 border-white object-cover followUp m-1' src={followOne} alt='test'></img>
                    <span className='mt-5 ml-5 text-xl font-medium text-white'>NAME GOES HERE</span>
                </div>
            </div>

            <div className='pt-5 m-auto flex text-center'>
                <div className='border p-4 bg-makeblue text-xl font-medium text-white m-auto followList'>5
                </div>

                <div className='fullListNames ml-2 flex bg-makeblue'>
                    <img className='rounded-full border-4 border-white object-cover followUp m-1' src={followTwo} alt='test'></img>
                    <span className='mt-5 ml-5 text-xl font-medium text-white'>NAME GOES HERE</span>
                </div>
            </div>

            <div className='pt-5 m-auto flex text-center'>
                <div className='border p-4 bg-makeblue text-xl font-medium text-white m-auto followList'>6
                </div>

                <div className='fullListNames ml-2 flex bg-makeblue'>
                    <img className='rounded-full border-4 border-white object-cover followUp m-1' src={followThree} alt='test'></img>
                    <span className='mt-5 ml-5 text-xl font-medium text-white'>NAME GOES HERE</span>
                </div>
            </div>

            <div className='pt-5 m-auto flex text-center'>
                <div className='border p-4 bg-makeblue text-xl font-medium text-white m-auto followList'>7
                </div>

                <div className='fullListNames ml-2 flex bg-makeblue'>
                    <img className='rounded-full border-4 border-white object-cover followUp m-1' src={followFour} alt='test'></img>
                    <span className='mt-5 ml-5 text-xl font-medium text-white'>NAME GOES HERE</span>
                </div>
            </div>

            <div className='pt-5 m-auto flex text-center'>
                <div className='border p-4 bg-makeblue text-xl font-medium text-white m-auto followList'>8
                </div>

                <div className='fullListNames ml-2 flex bg-makeblue'>
                    <img className='rounded-full border-4 border-white object-cover followUp m-1' src={followFive} alt='test'></img>
                    <span className='mt-5 ml-5 text-xl font-medium text-white'>NAME GOES HERE</span>
                </div>
            </div>
            
        </div>

        
    )
}

export default Follow