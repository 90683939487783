import { Button, Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, TextField, makeStyles } from '@material-ui/core';
import axios from 'axios';
import React, { useState, useContext, useEffect } from 'react'
import M4Loader from '../../Globals/CommonComponents/M4Loader';
import { AlertContext } from '../../Globals/Hooks/AlertContext';
import { ClearOutlined, DeleteOutline, EditOutlined, SaveOutlined } from '@material-ui/icons';

const useStyles = makeStyles({
    input: {
        width: "80%"
    }
});

function CriteriaDefaults() {
    const classes = useStyles();

    const [headers, setHeaders] = useState([]);
    const [isEditing, setEditing] = useState([]);
    const [isHeaderLoading, setHeaderLoading] = useState([]);

    const { setAlert } = useContext(AlertContext);

    const newHeaderId = 0;

    const loadHeaders = async () => {
        return await axios.get('/criteria')
            .then((result) => {
                return result.data;
            });
    };

    useEffect(() => {
        Promise.all([loadHeaders()])
            .then((result) => {
                setHeaders(result[0]);
            });
    }, []);

    const isEditingRow = (headerId) => {
        return isEditing.includes(headerId);
    };

    const isLoadingRow = (headerId) => {
        return isHeaderLoading.includes(headerId);
    }

    const addHeader = () => {
        setHeaders(headers.concat([{
            header: "",
            id: newHeaderId
        }]));
        setEditing(isEditing.concat([newHeaderId]));
    };

    const insertHeader = async () => {
        axios.post('/criteria', {
            'header': headers.find(h => h.id === newHeaderId).header
        })
        .then(async () => {
            setAlert('Header Added', 'success');
            setEditing(isEditing.filter(id => id !== newHeaderId));
            
            const newHeaders = await loadHeaders();
            setHeaders(newHeaders);
        })
    }

    const editHeader = (newValue, headerId) => {
        const newHeaders = headers.map(header => {
            if(header.id === headerId){
                header.header = newValue;
            }
            return header;
        });
        setHeaders(newHeaders);
    };

    const saveHeader = (headerId) => {
        setHeaderLoading(isHeaderLoading.concat(headerId));

        axios.put('/criteria',
            {
                "headerId": headerId,
                "header": headers.find(h => h.id === headerId).header
            }
        ).then(() => {
            setHeaderLoading(isHeaderLoading.filter(h => h.id !== headerId));
            setEditing(isEditing.filter(id => id !== headerId));
        })
        .catch(() => {
            setHeaderLoading(isHeaderLoading.filter(h => h.id !== headerId));
            setAlert('Error Saving Header', 'error');
        });
    };

    const deleteHeader = (headerId) => {
        setHeaderLoading(isHeaderLoading.concat(headerId));

        axios.delete(`/criteria/${headerId}`)
        .then(() => {
            setHeaders(headers.filter(h => h.id !== headerId));
        });
    };

    const handleClear = () => {
        setHeaders(headers.filter(h => h.id !== newHeaderId));
    };

    if(headers === null) return null;

    return (
        <div>
            <h1 className='m-auto w-full my-5 text-makeblue text-2xl'>Criteria Headers</h1>
            <Divider />
            <List>
                {headers.map(item => {
                    return (
                        <ListItem>
                            {isEditingRow(item.id) ? 
                                <>
                                    <TextField 
                                        value={item.header} 
                                        onChange={e => editHeader(e.target.value, item.id)}
                                        disabled={isLoadingRow(item.id)}
                                        className={classes.input}
                                    /> 
                                    {isLoadingRow(item.id) && 
                                        <ListItemIcon>
                                            <M4Loader size={18}/>
                                        </ListItemIcon>
                                    }
                                </>
                                : <div className='cursor-pointer' onClick={() => setEditing(isEditing.concat([item.id]))}>
                                    {item.header}
                                </div>
                            }
                            {isEditingRow(item.id) &&
                                <ListItemSecondaryAction>
                                    {item.header !== "" ?
                                        <Button 
                                            onClick={() => {
                                                if(item.id === newHeaderId) {
                                                    insertHeader();
                                                }
                                                else {
                                                    saveHeader(item.id)
                                                }
                                            }}
                                            disabled={headers.find(h => h.id === item.id).header === ""}
                                        >
                                            <SaveOutlined />
                                        </Button> 
                                        :
                                        <Button
                                            onClick={() => {
                                                handleClear();
                                            }}
                                        >
                                            <ClearOutlined />
                                        </Button>
                                    }
                                </ListItemSecondaryAction>
                            }
                            {!isEditingRow(item.id) &&
                                <ListItemSecondaryAction>
                                    <Button onClick={()=>{setEditing(isEditing.concat([item.id]))}}>
                                        <EditOutlined />
                                    </Button>
                                    <Button onClick={() => deleteHeader(item.id)}>
                                        <DeleteOutline />
                                    </Button>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                    );
                })}
                <ListItem>
                    <Button
                        onClick={() => addHeader()}
                        disabled={headers.some(h => h.id === newHeaderId)}
                    >
                        Add Header
                    </Button>
                </ListItem>
            </List>
        </div>
    );
}

export default CriteriaDefaults;