import React, { useState } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import {  useFormik } from "formik";
import {
    Container, Row, Col, Form, Button, Card
} from 'react-bootstrap';

const PasswordModal = (props) => {
    const [errorMessage] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);

    let validationSchema = yup.object().shape({
        password: yup.string().required("Password is required"),
        password_confirmation: yup.string().required("Password is required"),
    });

    const Reset = useFormik({
        initialValues: {
            password: "",
            password_confirmation: "",
        },
        onSubmit: (values) => {
            console.log(values);
            values.token = urlParams.get('token');

            axios.post('/password/reset/', values).then(res => {
                // console.log(res);
                alert("password has been changes");
                // if (res.data.status === 200){
                //   window.location.pathname = '/user'
                // }
            }).catch((err) =>  {
                if(err) {
                    console.log(err);
                    // alert ('login failed')
                }
            });
        },
        validationSchema,
    });


    return (

        <React.Fragment>
            <Container className='text-center text-2xl pt-12 w-full m-auto  font-hairline'>
                <Row className='xl:w-1/2 lg:w-1/2 m-auto'>
                    <Col>
                        <Card  style={{color: 'white', backgroundColor: 'rgb(53,142,215)'}} className="login-card content-card">
                            <Card.Header style={{fontSize:'3rem', textAlign:'center', fontWeight:'100'}}>Reset your password now</Card.Header>
                            <Card.Body style={{textAlign:'center'}} className="p-5">
                                <div id="ErrorMessage" style={{color: 'red', marginBottom: 10 + 'px'}} >{ errorMessage }</div>
                                <Form id='login-form' onSubmit={Reset.handleSubmit}>
                                    <Form.Group as={Row} controlId="formBasicEmail">
                                        <Form.Label column sm="3"></Form.Label>
                                        <Col sm="9">
                                            <div className='my-5'>
                                                <input disabled className='w-4/5 m-auto rounded-lg bg-makebluetwo block p-3' type='email'  id='email' placeholder='Email' onChange={Reset.handleChange} value={Reset.values.email}></input>
                                                {Reset.touched.email && Reset.errors.email ? <div className='w-4/5 m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3'>{Reset.errors.email}</div> : null}
                                            </div>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formBasicEmail">
                                        <Form.Label column sm="3"></Form.Label>
                                        <Col sm="9">
                                            <div className='my-5'>
                                                <input className='w-4/5 m-auto rounded-lg bg-makebluetwo block p-3' type='password'  id='password' placeholder='Password' onChange={Reset.handleChange} value={Reset.values.password}></input>
                                                {Reset.touched.password && Reset.errors.password ? <div className='w-4/5 m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3'>{Reset.errors.password}</div> : null}
                                            </div>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formBasicEmail">
                                        <Form.Label column sm="3"></Form.Label>
                                        <Col sm="9">
                                            <div className='my-5'>
                                                <input className='w-4/5 m-auto rounded-lg bg-makebluetwo block p-3' type='password'  id='password_confirmation' placeholder='Confirm Password' onChange={Reset.handleChange} value={Reset.values.password_confirmation}></input>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                    <Button
                                        style={{backgroundColor:'#8DC642',fontSize:'1rem',textAlign:'center',padding:'10px 20px',borderRadius:'0.5rem', boxShadow:'0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06', marginBottom:'.5rem'}}
                                        variant="primary" type="submit">
                                        Change Password
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default PasswordModal;