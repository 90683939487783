import React from 'react'
import footLogo from '../Assets/m48-4c-no-tag.png'
import FooterModal from './FooterModal'

function Footer() {

    return (
    <div className="container m-auto px-4 footer-area">
        <div className='grid md:grid-cols-3 md:grid-cols-2 sm:my-10 gap-4 justify-center items-center'>
            <div className="footer-logo">
                <img className='footer-img' src={footLogo} alt='m4-color' width="200"></img>
            </div>
            <div className='text-center sm:block xl:text-base sm:text-2xl font-thin copyright'>
                    Make48 LLC | 1712 Main St., Ste. 364, Kansas City, MO 64108
            </div>
            <div className='md:text-right text-center sm:block sm:my-5'>

                <FooterModal/>

            </div>
        </div>
    </div>
    )
}
export default Footer;
