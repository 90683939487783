import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

const submission = [
    '1 Minute Video',
    'Sales Sheets. (Custom Created)',
    'Prototype',
    'All of the Above',
  ];

const Submission = (props) => {
  const classes = useStyles();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    setPersonName(event.target.value );
    if (event.target.name === 'submission_requirements') {
      props.updateNewChallenge({ submission_requirements: event.target.value.toString() });
    }
  }
  

  return (
    <div className='flex'>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Submission on deadline...</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          name='submission_requirements'
        >
          {submission.map((Submission) => (
            <MenuItem key={Submission} value={Submission}>
              <Checkbox checked={personName.indexOf(Submission) > -1} />
              <ListItemText primary={Submission} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
    )
}
export default Submission