import React, { Component } from 'react'
import LeaderTwo from '../Assets/Userpic-1.png'


export class Secondplace extends Component {
    render() {
        return (
        <div className='block'>

            <figure className='flex rounded-full border shadow p-3 m-auto xl:mx-10 sm:mx-auto mt-10 object-contain figureTwo'>   
                <div>
                <img className='secondWinner' src={LeaderTwo} alt='test'/>
                </div>                     
            </figure>

            <div className='placeNumbers flex xl:w-3/12 sm:w-1/12 py-4 flex-col border bg-leaderGray text-white text-xl m-auto xl:mt-20 md:mt-2 sm:mt-2'>2

            </div>
        </div>

        )
    }
}

export default Secondplace
