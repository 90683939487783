import React, { useState } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import {  useFormik } from "formik";
import {
  Container, Row, Col, Form, Button, Card
} from 'react-bootstrap';



const PasswordModal = (props) => {
const [errorMessage] = useState(false);

let validationSchema = yup.object().shape({
  email: yup.string().email("Email Must be Valid ").required("Email is required"),
});

const Reset = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      axios.post('/password/reset/request', values).then(res => {
        document.getElementById("check-email").style.display = "block";
        document.getElementById("login-form").style.display = "none";
         window.token = res.data.access_token;
         window.user = res.data.user;
         props.setUserFromLogin(res.data)
        // if (res.data.status === 200){
        //   window.location.pathname = '/'
        // }
      }).catch((err) =>  {
        if(err) {
          console.log(err);
          // alert ('login failed')
        }
      });
    },
    validationSchema,
  });

  
    return (
      
  <React.Fragment>
    <Container>
      <Row>
        <Col>
          <Card  style={{color: 'white', backgroundColor: 'rgb(53,142,215)'}} className="login-card content-card">
            <Card.Header style={{fontSize:'3rem', textAlign:'center', fontWeight:'100'}}>Reset Password</Card.Header>
            <Card.Body style={{textAlign:'center'}}>
              <div id="ErrorMessage" style={{color: 'red', marginBottom: 10 + 'px'}} >{ errorMessage }</div>
              <div id='check-email' className="check-email">
                <h5>Your password request has been submitted! Please check your email</h5>
              </div>
              <Form id='login-form' onSubmit={Reset.handleSubmit}>
                <Form.Group as={Row} controlId="formBasicEmail">
                  <Form.Label column sm="3"></Form.Label>
                  <Col sm="9">
                <div className='my-5'>
                  <input className='w-4/5 m-auto rounded-lg bg-makebluetwo block p-3' type='email'  id='email' placeholder='Email' onChange={Reset.handleChange} value={Reset.values.email}></input>
                   {Reset.touched.email && Reset.errors.email ? <div className='w-4/5 m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3'>{Reset.errors.email}</div> : null}
                  </div>
                  </Col>
                </Form.Group>
                <Button 
                style={{backgroundColor:'#8DC642',fontSize:'1rem',textAlign:'center',padding:'10px 20px',borderRadius:'0.5rem', boxShadow:'0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06', marginBottom:'.5rem'}} 
                variant="primary" type="submit">
                  Send Password Reset Link
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
        </React.Fragment>
)
}

export default PasswordModal;