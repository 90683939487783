import { Button, makeStyles } from '@material-ui/core';
import { PublishOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => {
    return ({
        root: {
            color: theme.palette.secondary.main
        },
        button: {
            marginRight: theme.spacing(2)
        }
    });
});

function M4FileUploader(props) {
    const classes = useStyles();

    const [file, setFile] = useState({});

    useEffect(() => {
        if(file.data){
            props.onFileUpload(file);
        }
    }, [file]);

    const handleFileUploaded = (e) => {
        const fileName = e.target.files[0].name;
        const fileSize = e.target.files[0].size;

        var reader = new FileReader();
        reader.onload = (event) => {
            setFile({
                ...file,
                data: event.target.result,
                fileName: fileName,
                fileSize: fileSize
            });
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    return(
        <div key={props.thing} className={`${props.className}`}>
            <input type="file" accept={props.accept ?? "*"} id={"upload" + props.index} className='hidden' onChange={handleFileUploaded}/>
            <label
                htmlFor={"upload" + props.index}
                className={`${classes.root}`}
            >
                <Button 
                    variant='contained' 
                    color='secondary' 
                    startIcon={<PublishOutlined />}
                    component="span"
                    className={classes.button}
                >
                    {file.fileName || props.label || "Upload"}
                </Button>
            </label>
        </div>
    );
}

export default M4FileUploader;