import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import axios from "axios"
import * as yup from "yup"
import { useFormik } from "formik"

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
      width: "100%",
    },
  },
}))

const CreateCompanies = (props) => {
  const [companyLogo, setCompanyLogo] = useState()
  const pattern = "[0-9]{3}-[0-9]{3}-[0-9]{4}"

  let validationSchema = yup.object().shape({
    name: yup.string().required("Company Name is required"),
    first_name: yup.string().required("Sponsor Contact Name is required"),
    phone: yup.string().matches(pattern, "Invalid Format").required("Phone is required"),
    address: yup.string().required("Address is required"),
    state: yup.string().required("State is required"),
    zip: yup.string().required("Zip is required"),
    city: yup.string().required("City is required"),
  })
  const CreateCompany = useFormik({
    initialValues: {
      name: "",
      first_name: "",
      phone: "",
      address: "",
      state: "",
      zip: "",
      city: "",
    },
    onSubmit: (values) => {
      values.company_logo = companyLogo

      let data = new FormData()
      for (const property in values) {
        data.append(property, values[property])
      }
      axios
        .post("/company", data, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res)
          window.location.reload()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    validationSchema,
  })

  // console.log(newCompany);
  const classes = useStyles()

  return (
    <div className="xl:p-10 sm:p-0 overflow-x-hidden">
      <form className={classes.root} onSubmit={CreateCompany.handleSubmit} encType="multipart/form-data">
        <div className="py-5">
          <h1 className="m-auto w-full my-5 border-b border-dash text-makeblue text-2xl">Company Information</h1>
          <div className="flex flex-wrap -mx-3 mb-10">
            <div className="md:w-1/3 w-full px-3">
              <TextField
                id="companyName"
                label="Company Name"
                type="text"
                name="name"
                placeholder="required*"
                onChange={CreateCompany.handleChange}
                value={CreateCompany.values.name}
              />
              <div className='relative'>
                {CreateCompany.touched.name && CreateCompany.errors.name ? (
                  <div className="text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{CreateCompany.errors.name}</div>
                ) : null}
              </div>
            </div>
            <div className="md:w-1/3 w-full px-3">
              <TextField
                id="contactName"
                label="Sponsor Contact Name"
                type="text"
                name="first_name"
                placeholder="required*"
                onChange={CreateCompany.handleChange}
                value={CreateCompany.values.first_name}
              />
              <div className='relative'>
                {CreateCompany.touched.first_name && CreateCompany.errors.first_name ? (
                  <div className="m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{CreateCompany.errors.first_name}</div>
                ) : null}
              </div>
            </div>
            <div className="md:w-1/3 w-full px-3">
              <TextField
                id="contactNumber"
                label="Contact Number"
                type="tel"
                name="phone"
                placeholder="XXX-XXX-XXXX*"
                inputProps={{ maxLength: 12 }}
                onChange={CreateCompany.handleChange}
                value={CreateCompany.values.phone}
              />
              <div className='relative'>
                {CreateCompany.touched.phone && CreateCompany.errors.phone ? (
                  <div className="m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{CreateCompany.errors.phone}</div>
                ) : null}
              </div>
            </div>
          </div>
          <h1 className="m-auto w-full border-b border-dash my-5 text-makeblue text-2xl"> Address of Business </h1>
          <div className="flex flex-wrap -mx-3 mb-10">
            <div className="md:w-1/2 w-full px-3 mb-3">
              <TextField id="address" name="address" label="Street Address" type="text" placeholder="" onChange={CreateCompany.handleChange} value={CreateCompany.values.address} />
              <div className='relative MuiTextField-root'>
                {CreateCompany.touched.address && CreateCompany.errors.address ? (
                  <div className="text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{CreateCompany.errors.address}</div>
                ) : null}
              </div>
            </div>
            <div className="md:w-1/2 w-full px-3 mb-3">
              <TextField id="city" name="city" label="City" type="text" placeholder="" onChange={CreateCompany.handleChange} value={CreateCompany.values.city} />
              <div className='relative MuiTextField-root'>
                {CreateCompany.touched.city && CreateCompany.errors.city ? (
                  <div className="text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{CreateCompany.errors.city}</div>
                ) : null}
              </div>
            </div>
            <div className="md:w-1/2 w-full px-3 mb-3">
              <TextField id="state" name="state" label="State" type="text" placeholder="" onChange={CreateCompany.handleChange} value={CreateCompany.values.state} />
              {CreateCompany.touched.state && CreateCompany.errors.state ? (
                <div className="text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{CreateCompany.errors.state}</div>
              ) : null}
            </div>
            <div className="md:w-1/2 w-full px-3 mb-3">
              <TextField id="zip" name="zip" label="Postal Code" type="text" placeholder="" onChange={CreateCompany.handleChange} value={CreateCompany.values.zip} />
              {CreateCompany.touched.zip && CreateCompany.errors.zip ? (
                <div className="text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{CreateCompany.errors.zip}</div>
              ) : null}
            </div>
          </div>

          <div className="w-1/2">
            <label>
              <span className="text-makeblue mb-2 text-2xl flex">Upload Company Logo:</span>
              <span>(Jpeg,Jpg,Png)</span>
              <input
                type="file"
                name="company_logo"
                id="sampleFile"
                className="block w-1/2"
                alt="company logo"
                required
                onChange={(event) => {
                  setCompanyLogo(event.target.files[0])
                }}
              />
            </label>
          </div>
          <div className="w-full m-auto text-center text-white">
            <button className="w-1/5 p-3 mt-5 bg-makeblue rounded" type="submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
export default CreateCompanies
