import React from 'react'
import { useState } from 'react';
import { createContext } from 'react';

const initialState = {
    message: '',
    type: ''
};

export const AlertContext = createContext({
    ...initialState,
    setAlert: () => {}
});

export const AlertProvider = ({ children }) => {
    const [message, setMessage] = useState('');
    const [type, setType] = useState('');
    const [open, setOpen] = useState(false);

    const setAlert = (message, type) => {
        setMessage(message);
        setType(type);
        setOpen(true);

        setTimeout(() => {
            setOpen(false);
        }, 5000);
    }

    return (
        <AlertContext.Provider
            value={{
                message,
                type,
                open,
                setAlert
            }}
        >
            {children}
        </AlertContext.Provider>
    )
}