import React from "react"
import Logop from "../Assets/m48-p.png"
import { useFormik } from "formik"
import * as yup from "yup"
import axios from "axios"

const Started = (props) => {
  let validationSchema = yup.object().shape({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    email: yup.string().email("Email Must be Valid").required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  const Signup = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      // console.log(values)
      props.updateNewUser(values)
      values.role_id = 2 //set default to user role=2
      axios
        .post("/user", values)
        .then((res) => {
          let newUserObject = {
            access_token: res.data,
            user: values,
          }
          props.createNewUser(newUserObject)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    validationSchema,
  })
  if (!props.user) {
    return (
      <div className="bg-makeblue text-white p-10">
        <div className="w-4/5 m-auto xl:flex sm:block">
          <div className="xl:w-1/2 sm:w-auto">
            <h1 className="text-5xl font-thin">Get Started Now</h1>
            <span className="text-2xl font-hairline pt-3 pr-5">Sign up and quickly get up and running with Make48. Start or join a challenge and help build the future.</span>

            <form onSubmit={Signup.handleSubmit}>
              <div className="my-5">
                <input
                  onChange={Signup.handleChange}
                  value={Signup.values.first_name}
                  className="w-4/5 rounded-lg bg-transparent placeholder-white block p-3 border-solid border-2 border-opacity-25 border-white"
                  type="first_name"
                  id="first_name"
                  placeholder="First Name"
                ></input>
                {Signup.touched.first_name && Signup.errors.first_name ? (
                  <div className="w-4/5 text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{Signup.errors.first_name}</div>
                ) : null}
              </div>
              <div className="my-5">
                <input
                  onChange={Signup.handleChange}
                  value={Signup.values.last_name}
                  className="w-4/5 rounded-lg bg-transparent placeholder-white block p-3 border-solid border-2 border-opacity-25 border-white"
                  type="last_name"
                  id="last_name"
                  placeholder="Last Name"
                ></input>
                {Signup.touched.last_name && Signup.errors.last_name ? (
                  <div className="w-4/5 text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{Signup.errors.last_name}</div>
                ) : null}
              </div>
              <div className="my-5">
                <input
                  onChange={Signup.handleChange}
                  value={Signup.values.email}
                  className="w-4/5 rounded-lg bg-transparent placeholder-white block p-3 border-solid border-2 border-opacity-25 border-white"
                  type="email"
                  id="email"
                  placeholder="E-mail"
                ></input>
                {Signup.touched.email && Signup.errors.email ? <div className="w-4/5 text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{Signup.errors.email}</div> : null}
              </div>
              <div className="my-5">
                <input
                  onChange={Signup.handleChange}
                  value={Signup.values.password}
                  className="w-4/5 rounded-lg bg-transparent placeholder-white block p-3 border-solid border-2 border-opacity-25 border-white"
                  type="password"
                  id="password"
                  placeholder="Password"
                ></input>
                {Signup.touched.password && Signup.errors.password ? (
                  <div className="w-4/5 text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{Signup.errors.password}</div>
                ) : null}
              </div>
              <button className="m-5 mx-0 p-4 xl:w-3/12 md:w-3/12 bg-button-green text-base shadow rounded-lg" type="submit">
                Sign up
              </button>
            </form>
          </div>
          <div className="xl:w-1/2 sm:w-auto text-center m-auto">
            <img src={Logop} alt="idea" className="m-auto xl:mt-0 sm:mt-0 sm:p-10 xl:p-0"></img>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="bg-makeblue text-white p-10">
        <div className="w-4/5 m-auto xl:flex sm:block">
          <div className="xl:w-1/2 sm:w-auto m-auto">
            <h1 className="text-5xl font-thin">You're Logged In!</h1>
            <span className="text-2xl font-hairline pt-3 pr-5">Sign up for new challenges or review the ones you've applied too from the dashboard!</span>
          </div>
          <div className="xl:w-1/2 sm:w-auto text-center m-auto">
            <img src={Logop} alt="idea" className="m-auto xl:mt-0 sm:mt-0 sm:p-10 xl:p-0"></img>
          </div>
        </div>
      </div>
    )
  }
}

export default Started
