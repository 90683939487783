import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import M4FileUploader from '../Globals/CommonComponents/M4FileUploader';


const useStylesFull = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100% !important',
  },
}));

const Projdescription = (props) => {
  const classes = useStylesFull();

  const handleUploadFile = (name, file) => {
    props.updateNewChallenge({[name]: file});
  }

  return (
    <div>
      <div className='xl:flex sm:block'>
        <div className={classes.root}>
          <TextField
            id='challenge-description'
            label='Full Challenge Description.'
            multiline rows={5}
            variant='outlined'
            onChange={(newValue) => { props.updateNewChallenge({ description: newValue.target.value }) }}
          />

        </div>
      </div>
      <div>
        <div className='w-auto block pt-10'>
        <h1 className='m-auto w-full my-5 border-b border-dash text-makeblue text-2xl'> Image Uploads </h1>
        <p className='pb-10 font-bold'>Selected images will begin upload upon clicking the submission button at the end of the form.</p>
          {/* <label>
            <span className='text-makeblue text-lg mb-2 pt-3'>Challenge Main Display Photo</span>
            <input type="file" alt="Banner Image" id="banner" className='block w-1/2'
              onChange={(newValue) => { props.updateNewChallenge({ banner_image: newValue.target.files[0] }) }}
              accept="image/png, image/gif, image/jpeg, image/jpg" />
            {props.errors.banner_image && <div class="m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{props.errors.banner_image[0]}</div>}
          </label> */}
          <M4FileUploader accept={"image/png, image/gif, image/jpeg, image/jpg"} label="Banner Image" onFileUpload={file => handleUploadFile("banner_image", file)} index={0} />
        </div>
      </div>

      <div className='xl:flex sm:block w-full my-10'>
        <div className='w-1/2 xl:m-auto sm:m-10'>
          {/* <label>
            <span className='text-makeblue text-lg mb-2'>First photo of example design</span>
            <input type="file" alt="Image" id="sample1" className='block w xl:m-auto sm:m-10-1/2'
              onChange={(newValue) => { props.updateNewChallenge({ example_image_1: newValue.target.files[0] }) }}
              accept="image/png, image/gif, image/jpeg, image/jpg" />
            {props.errors.example_image_1 && <div class="m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{props.errors.example_image_1[0]}</div>}
          </label> */}
          <M4FileUploader accept="image/png, image/gif, image/jpeg, image/jpg" label="First photo example" onFileUpload={file => handleUploadFile("example_image_1", file)} index={1} />
        </div>

        <div className='w-1/2 xl:m-auto sm:m-10'>
          {/* <label>
            <span className='text-makeblue text-lg mb-2'>Second photo of example design</span>
            <input type="file" alt="Image" id="sample2" className='block w-1/2'
              onChange={(newValue) => { props.updateNewChallenge({ example_image_2: newValue.target.files[0] }) }}
              accept="image/png, image/gif, image/jpeg, image/jpg" />
            {props.errors.example_image_2 && <div class="m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{props.errors.example_image_2[0]}</div>}
          </label> */}
          <M4FileUploader accept="image/png, image/gif, image/jpeg, image/jpg" label="Second photo example" onFileUpload={file => handleUploadFile("example_image_2", file)} index={2}/>
        </div>

        <div className='w-1/2 xl:m-auto sm:m-10'>
          {/* <label>
            <span className='text-makeblue text-lg mb-2'>Third photos of example design</span>
            <input type="file" alt="Image" id="sample3" className='block w-1/2'
              onChange={(newValue) => { props.updateNewChallenge({ example_image_3: newValue.target.files[0] }) }}
              accept="image/png, image/gif, image/jpeg, image/jpg" />
            {props.errors.example_image_3 && <div class="m-auto text-white bg-red-600 rounded-md mt-1 mb-4 p-1 pl-3">{props.errors.example_image_3[0]}</div>}
          </label> */}
          <M4FileUploader accept="image/png, image/gif, image/jpeg, image/jpg" label="Third photo example" onFileUpload={file => handleUploadFile("example_image_3", file)} index={3}/>
        </div>
      </div>
      <h1 className='m-auto w-full my-5 border-b border-dash text-makeblue text-2xl'> Documentation </h1>
      <h1 className='m-auto w-full mt-20 text-makeblue text-2xl border-b border-dash'>Zoom Meetings (Date and Time)</h1>

      <label>
        <TextField
          id="zoom"
          label="Zoom Url"
          type="zoom"
          placeholder=''
          onChange={(newValue) => { props.updateNewChallenge({ zoom_link: newValue.target.value }) }}
        />
      </label>

      <div className='flex my-10'>
        <TextField
          id="zoomDateOne"
          label="Call One"
          type="date"
          defaultValue="yyyy-MM-dd"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(newValue) => { props.updateNewChallenge({ zoom_date_one: newValue.target.value }) }}
        />

        <TextField
          id="zoomTimeOne"
          label="Time"
          type="time"
          defaultValue="yyyy-MM-dd"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(newValue) => { props.updateNewChallenge({ zoom_time_one: newValue.target.value }) }}
        />
      </div>

      <div className='flex my-10'>
        <TextField
          id="zoomDateTwo"
          label="Call Two"
          type="date"
          defaultValue="yyyy-MM-dd"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(newValue) => { props.updateNewChallenge({ zoom_date_two: newValue.target.value }) }}
        />

        <TextField
          id="zoomTimeTwo"
          label="Time"
          type="time"
          defaultValue="yyyy-MM-dd"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(newValue) => { props.updateNewChallenge({ zoom_time_two: newValue.target.value }) }}
        />
      </div>

      <div className='flex my-10'>
        <TextField
          id="zoomDateThree"
          label="Call Three"
          type="date"
          defaultValue="yyyy-MM-dd"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(newValue) => { props.updateNewChallenge({ zoom_date_three: newValue.target.value }) }}
        />

        <TextField
          id="zoomTimeThree"
          label="Time"
          type="time"
          defaultValue="yyyy-MM-dd"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(newValue) => { props.updateNewChallenge({ zoom_time_three: newValue.target.value }) }}
        />
      </div>

    </div>
  )
}



export default Projdescription