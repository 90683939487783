import React, {useState} from 'react'
import closeModal from '../Assets/close-circular.png'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Newslettermodal from './Newslettermodal'

let closeImg = {cursor:'pointer', float:'right', marginTop: '5px', width: '30px'};

 

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#358ED7',
        border: '2px solid #358ED7',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const FooterModal = (props) =>  {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Button style={{
                backgroundColor: 'transparent',
                color: 'black',
                borderRadius: '.5rem',
                padding:'2px 50px',
                fontSize:'1rem',
                marginTop:'-20px',
                border: '2px solid rgba(255, 255, 255, .5)',
                outline:'none',
                textTransform:'inherit',
            }}
            onClick={handleOpen}>
                Newsletter
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                    <div className='close-modal'>
                     <img className='close-icon' src={closeModal} alt='Close_Icon' onClick={handleClose}  style={closeImg}></img>
                    </div>
                    <Tabs>
                        <TabList>
                            <div className='flex'>
                                <Tab></Tab>
                            </div>
                        </TabList>
{/* 
                        <TabPanel>
                            <Login setUserFromLogin={props.setUserFromLogin} authTest={props.authTest}/>
                            
                        </TabPanel>
                        
                        <TabPanel>
                            <Signupmodal createNewUser={props.createNewUser} updateNewUser={props.updateNewUser} {...props}/>
                        </TabPanel> */}
                        
                        <TabPanel>
                            <Newslettermodal setUserFromLogin={props.setUserFromLogin} createNewUser={props.createNewUser} updateNewUser={props.updateNewUser} {...props}/>
                        </TabPanel>

                    </Tabs>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
export default FooterModal