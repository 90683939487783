import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';

const styles = makeStyles({
    avatar: {
        backgroundColor: props => props.color,
        width: props => {
            switch(props.size){
                case 'large':
                    return '10rem';
                default:
                    return '';
            }
        },
        height: props => {
            switch(props.size){
                case 'large':
                    return '10rem';
                default:
                    return '';
            }
        },
        fontSize: props => {
            switch(props.size){
                case 'large':
                    return '4rem';
                default:
                    return '';
            }
        }
    }
});

const M4Avatar = (props) => {
    const classes = styles(props);

    const getInitials = () => {
        if(!props.user){
            return "";
        }
        const firstInitial = props.user.first_name[0];
        const lastInitial = props.user.last_name[0];
        return firstInitial + lastInitial;
    }

    if(!props.user) {
        return <Avatar className={`${props.className} ${classes.avatar} from-gray-200 to-gray-800 transition-all animate-pulse`}></Avatar>
    }
    
    return (
        <Avatar className={`${props.className} ${classes.avatar}`} >
            {getInitials() ?? props.userImage}
        </Avatar>
    )
};

export default M4Avatar;